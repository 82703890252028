/* eslint-disable react/react-in-jsx-scope */
import { Fragment, h } from "preact";
import { Ref, useEffect, useRef, useState } from "preact/hooks";
import { DlocFilters, formatStatus, LegalFilters } from "../../data";
import "./styles.scss";

interface FilterProps {
	filter: string;
	label: string;
	filters: LegalFilters | DlocFilters;
	onChange: (filter: string, value: string[]) => void;
	onUpdateCustom: (filter: string, value: string) => void;
	values: {
		value: string;
		displayValue?: string;
	}[];
}

export const StringArrayFilter = ({
	filter,
	label,
	filters,
	onChange,
	onUpdateCustom,
	values,
}: FilterProps): JSX.Element => {
	const [showCheckboxes, setShowCheckboxes] = useState<boolean>(false);
	const inputRefs: Record<string, Ref<HTMLInputElement>> = {};
	const divRef = useRef<HTMLDivElement>(null);

	values.forEach((val) => {
		inputRefs[val.value] = useRef<HTMLInputElement>(null);
	});

	useEffect(() => {
		const current = divRef?.current;

		const focusOut = () => {
			if (!current?.matches(":hover")) {
				setShowCheckboxes(false);
			}
		};

		current?.addEventListener("focusout", focusOut);

		return () => {
			current?.removeEventListener("focusout", focusOut);
		};
	});

	const getSelectedValues = () => {
		const selectedValues: string[] = [];
		values.forEach((val) => {
			const inputRef = inputRefs[val.value];
			if (inputRef.current.checked) {
				selectedValues.push(inputRef.current.value);
			}
		});

		return selectedValues;
	};

	const handleCheckboxClick = (
		e: h.JSX.TargetedEvent<HTMLInputElement | HTMLLabelElement, Event>,
		value: string,
	) => {
		e.preventDefault();
		const inputRef = inputRefs[value];
		inputRef.current.checked = !inputRef.current.checked;
		const selectedValues = getSelectedValues();
		onChange(filter, selectedValues);
	};

	const theFilter = filters[filter] as string[];
	const custom = (filters.custom as Record<string, string>)[filter];

	return (
		<div className="multiselect" ref={divRef} tabIndex={-1}>
			<div
				className="selectBox"
				onClick={() => {
					setShowCheckboxes(!showCheckboxes);
				}}
			>
				<label htmlFor={filter}>{label}: </label>
				<select>
					<option>
						{custom
							? theFilter.length === 0
								? custom
								: "Multiple"
							: theFilter.length === 0
								? "Show All"
								: theFilter.length === 1
									? filter !== "statuses"
										? theFilter[0]
										: formatStatus(theFilter[0])
									: "Multiple"}
					</option>
				</select>
				<div className="overSelect" />
			</div>
			{showCheckboxes && (
				<div id="checkboxes">
					<label htmlFor="custom">Custom: </label>
					<input
						id="custom"
						type="text"
						value={custom}
						onChange={(e) => {
							onUpdateCustom(filter, e.currentTarget.value);
						}}
					/>
					<hr />
					{values.map((val) => (
						<Fragment key={val.value}>
							<label
								htmlFor={val.value}
								onClick={(e) => {
									handleCheckboxClick(e, val.value);
								}}
							>
								<input
									ref={inputRefs[val.value]}
									type="checkbox"
									id={val.value}
									value={val.value}
									checked={theFilter.includes(val.value)}
									onClick={(e) => {
										handleCheckboxClick(e, val.value);
									}}
								/>
								{val.displayValue ?? val.value}
							</label>
						</Fragment>
					))}
				</div>
			)}
		</div>
	);
};
