/* eslint-disable react/react-in-jsx-scope */
import { h } from "preact";
import { useState } from "preact/hooks";
import { BackupCredentials } from "./BackupCredentials";
import { EnterBackupCodes } from "./EnterBackupCodes";
import { RecoverClient } from "./types";

// The Forgot password page
export const EnterBackupCodesPage = ({
	client,
}: {
	client: RecoverClient;
	path?: string;
}): JSX.Element => {
	const [code, setCode] = useState("");

	return code === "" ? (
		<EnterBackupCodes client={client} onSuccess={setCode} />
	) : (
		<BackupCredentials client={client} backupCode={code} />
	);
};
