import { CaseStatus } from "../../../../../../lib/data";

const caseData: {
	caseId: string;
	status: CaseStatus;
	statusChangeDate: Date;
} = {
	caseId: undefined,
	status: undefined,
	statusChangeDate: undefined,
};

export const useCloseCase = (): {
	caseId: string;
	status: CaseStatus;
	statusChangeDate: Date;
	setCaseData: (
		entryId: string,
		caseStatus: CaseStatus,
		changeDate: Date,
	) => void;
} => ({
	caseId: caseData.caseId,
	status: caseData.status,
	statusChangeDate: caseData.statusChangeDate,
	setCaseData: (entryId: string, caseStatus: CaseStatus, changeDate: Date) => {
		caseData.caseId = entryId;
		caseData.status = caseStatus;
		caseData.statusChangeDate = changeDate;
	},
});
