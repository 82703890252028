/* eslint-disable react/react-in-jsx-scope */
import { Fragment, h } from "preact";
import { useContext, useState } from "preact/hooks";

import { ReassignModal } from "../../components/reassign-modal";
import { EditModal } from "../../components/status-modal";
import { useCases } from "../../legalData";

import "./styles.scss";
import { LegalClientContext } from "../../components/LegalClientContext";
import { ViewEditNotes } from "./ViewEditNotes";
import {
	formatDate,
	legalNotes,
	MANY_ENTRIES,
} from "../../../../../../lib/data";
import { CaseStatus } from "../../../../../../lib/components/case-status";

const KEY_TO_NAME: Record<string, string> = {
	twitter: "Twitter",
	facebook: "Facebook",
	linkedin: "LinkedIn",
	instagram: "Instagram",
	snapchat: "Snapchat",
	tiktok: "TikTok",
	whatsapp: "WhatsApp",
	email: "EMail",
	phone: "Phone Number",
};

const RenderPerps = ({ perps }: { perps: Record<string, string[]> }) => (
	<Fragment>
		{Object.entries(perps)
			.map(([name, ids]) => (
				<RenderPerp key={name} name={KEY_TO_NAME[name] ?? name} ids={ids} />
			))
			.filter((v) => v !== null)}
	</Fragment>
);

const RenderPerp = ({ name, ids }: { name: string; ids: string[] }) => {
	if (ids === undefined || ids.length === 0) {
		return null;
	}

	return (
		<Fragment>
			<p>
				<b>{name}</b>
			</p>
			{ids.map((id) => (
				<p key={id}>{id}</p>
			))}
		</Fragment>
	);
};

export const ViewCasePage = ({
	id,
}: {
	id?: string;
	path?: string;
}): JSX.Element => {
	const [showEdit, setShowEdit] = useState<boolean>(false);
	const [showAssign, setShowAssign] = useState<boolean>(false);
	const [showViewNotes, setShowViewNotes] = useState<boolean>(false);
	const [noteToView, setNoteToView] = useState<legalNotes>(null);
	const { cases } = useCases();
	const legalClient = useContext(LegalClientContext);

	const entry = cases.find((e) => e.id === id);
	let notes = legalClient.caseNotes[id] ?? [];
	notes = notes.sort(
		(a, b) => a.createdDate.getTime() - b.createdDate.getTime(),
	);
	const onlyCaseForSurvivor =
		cases.filter((e) => e.entry.userID === entry.entry.userID).length === 1;

	if (!entry) {
		return null;
	}

	return (
		<Fragment>
			<main id="viewCase">
				<div id="caseDetails">
					<div id="caseBody">
						<a href="/dashboard" id="backAnchor">
							<p id="backLink">
								<img
									src={require("./chev-left.svg") as string}
									alt="left arrow"
								/>
								Back to dashboard
							</p>
						</a>

						<header>
							<div>
								<h2>Survivor Name: {entry.userContactInfo.name}</h2>
								<p>Survivor ID: {entry.entry.userID}</p>
								<p>Case ID: {entry.id}</p>
								<p>Perpetrator ID: {entry.perpID}</p>
								<p>Matched Users: {entry.numMatches}</p>
							</div>
							<div>
								<h2>Entry Created: {formatDate(entry.created)}</h2>
								<p>Entry Matched: {formatDate(entry.matchFound)}</p>
								<p>
									<CaseStatus
										status={entry.status}
										lastUpdated={entry.statusChanged}
									/>
								</p>
								<p>
									<a onClick={() => setShowEdit(true)}>Update status</a>
								</p>
							</div>
						</header>

						{entry.survivorHasManyEntries && (
							<div>
								<h2 className="highlighted centered">
									Survivor has at least {MANY_ENTRIES} entries in the system
								</h2>
							</div>
						)}

						<div className="detail">
							<h2>Contact Information</h2>
							<p>
								<b>Preferred Name</b>: {entry.userContactInfo.name}
							</p>
							<p>
								<b>Preferred Language</b>:{" "}
								{entry.userContactInfo.preferredLanguage ?? "Not specified"}
							</p>
							<div
								className={
									entry.userContactInfo.accommodationsNeeded
										? "highlighted"
										: ""
								}
							>
								<p>
									<b>Accommodations Needed</b>
									{!entry.userContactInfo.accommodationsNeeded &&
										": Not specified"}
								</p>
								{entry.userContactInfo.accommodationsNeeded && (
									<p
										dangerouslySetInnerHTML={{
											__html:
												entry.userContactInfo.accommodationsNeeded.replace(
													/\n/g,
													"<br />",
												),
										}}
									/>
								)}
							</div>
							{entry.userContactInfo.email && (
								<Fragment>
									<p>
										<b>Email Address</b>: {entry.userContactInfo.email}
									</p>
								</Fragment>
							)}
							{entry.userContactInfo.phone && (
								<Fragment>
									<p>
										<b>Phone Number</b>: {entry.userContactInfo.phone}
									</p>
								</Fragment>
							)}
							<Fragment>
								<p>
									<b>Campus When Entry Was Created</b>:{" "}
									{entry.userCampusAtTimeOfEntryCreation}
								</p>
							</Fragment>
						</div>

						<div className="detail">
							<h2>Where did it happen?</h2>
							<p>{entry.entry.incidentState}</p>
						</div>

						<div className="detail">
							<h2>Who was the offender?</h2>
							<RenderPerps perps={entry.entry.perpIDs} />
						</div>

						<div className="detail">
							<h2>Notes</h2>
							<ul>
								{notes.map((note) => (
									<li key={note.id}>
										<a
											onClick={() => {
												setNoteToView(note);
												setShowViewNotes(true);
											}}
										>{`Created ${note.createdDate.toLocaleString()} ${
											note.createdDate.getTime() === note.modifiedDate.getTime()
												? ""
												: `(Modified: ${note.modifiedDate.toLocaleString()})`
										}`}</a>
									</li>
								))}
							</ul>
							<button
								className="btn primary notes"
								onClick={() => {
									setShowViewNotes(true);
								}}
							>
								Add New Notes
							</button>
						</div>

						<footer>
							<a href="/dashboard" style={{ textDecoration: "none" }}>
								<button className="btn primary">Back to dashboard</button>
							</a>
							<a onClick={() => setShowAssign(true)}>
								Need to reassign the case?
							</a>
						</footer>
					</div>
				</div>
			</main>
			{showViewNotes && (
				<ViewEditNotes
					onClose={() => {
						setShowViewNotes(false);
						setNoteToView(null);
					}}
					caseId={id}
					survivorName={entry.userContactInfo.name}
					notes={noteToView}
					mode={noteToView !== null ? "view" : "edit"}
				/>
			)}
			{showAssign && (
				<ReassignModal
					closeFunc={() => setShowAssign(false)}
					entry={entry}
					onlyCaseForSurvivor={onlyCaseForSurvivor}
				/>
			)}
			{showEdit && (
				<EditModal onClose={() => setShowEdit(false)} entry={entry} />
			)}
		</Fragment>
	);
};
