/* eslint-disable react/react-in-jsx-scope */
import { h } from "preact";
import { useRef, useState } from "preact/hooks";
import "react-phone-number-input/style.css";

type PhoneNumberInputProps = {
	value: string | undefined;
	onChange: (value?: string) => void;
};

export const doFormat = (inputNumber: string) => {
	const pattern = "+* (***) ***-****";
	const strippedValue = inputNumber.replace(/[^0-9]/g, "");
	const chars = strippedValue.split("");
	let count = 0;

	let formatted = "";
	for (const c of pattern) {
		if (chars[count]) {
			if (/\*/.test(c)) {
				formatted += chars[count];
				count++;
			} else {
				formatted += c;
			}
		}
	}

	if (count < chars.length) {
		formatted += " ";
		while (count < chars.length) {
			formatted += chars[count];
			count++;
		}
	}

	return formatted;
};

const PhoneNumberInput = ({
	value,
	onChange,
}: PhoneNumberInputProps): h.JSX.Element => {
	const [inputValue, setInputValue] = useState<string | undefined>(
		doFormat(value),
	);
	const [focused, setFocused] = useState<boolean>(false);
	const ref = useRef<HTMLInputElement>(null);
	const cssProperties = {
		width: "90%",
		border: "1px solid",
		borderColor: focused ? "#00375B" : "#C6C5C5",
		padding: "10px 15px",
		backgroundColor: "#F5F7F7",
		fontSize: "x-large",
		height: "25px",
		borderRadius: "0px",
		outline: "none",
	};

	const handleChange = (e: h.JSX.TargetedKeyboardEvent<HTMLInputElement>) => {
		const { selectionStart, value: inputVal } = e.currentTarget;
		const formattedValue = doFormat(inputVal);

		if (e.key === undefined) {
			setInputValue(formattedValue);
		} else if (e.key.replace(/[^0-9]/g, "").length || e.key === "Backspace") {
			setInputValue(formattedValue);
			ref.current.value = formattedValue;
			const caretPos = doFormat(inputVal.slice(0, selectionStart)).length;
			ref.current.setSelectionRange(caretPos, caretPos);
		}
		onChange(`+${formattedValue.replace(/[^0-9]/g, "")}`);
	};

	const handleClick = () => {
		if (inputValue === "") {
			setInputValue("+1 (");
		}
		setFocused(true);
	};

	const handleBlur = () => {
		if (inputValue === "+1 (") {
			setInputValue("");
		}
		setFocused(false);
	};

	return (
		<div>
			<input
				style={cssProperties}
				ref={ref}
				id="phone_number"
				value={inputValue}
				name="phone_number"
				onBlur={handleBlur}
				onClick={handleClick}
				onKeyUp={handleChange}
			/>
		</div>
	);
};

export default PhoneNumberInput;
