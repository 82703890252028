import { envelopeContents } from "../data";
import sodium from "libsodium-wrappers-sumo";

import { makeCode } from "../passphrase";
import { Box } from "./box";
import { Secretbox } from "./secretbox";
import { OPRF } from "./oprf";

const KDF_CONTEXT = "callisto";
const KDF_SUBKEY_INDEX = 0;
const KDF_SUBKEY_SECRETBOX = 1;
const KDF_SUBKEY_POINT = 2;

/**
 * [OPAQUE](https://tools.ietf.org/html/draft-krawczyk-cfrg-opaque-01) implements
 * a PAKE without requiring the server to receive/send any sensitive values in
 * their original form.
 *
 * This implementation modifies OPAQUE in a number of ways:
 *
 *   * First, it adds deniability to the process, such that short of a user's
 *     credentials being known it is impossible to positively identify them in
 *     the database.
 *
 *   * Second, it adds key derivation hardening to the process to make
 *     dictionary attacks prohibitively expensive.
 *
 *   * Third, it adds the potential for account recovery through "backup codes"
 *     which should be presented to the user upon account creation.
 */
export module OPAQUE {
	export interface Identity {
		key: Uint8Array;
		index: string;
	}

	export interface Backup {
		code: string;
		encrypted: Uint8Array;
	}

	export interface BackupContents {
		keys: envelopeContents;
		username: string;
	}

	/**
	 * Generate the user's keys that will be used later for entry/contact data
	 * encryption.
	 *
	 * @return Keys that are usable to encrypt/decrypt data.
	 */
	export const generateKeys = (): envelopeContents => {
		const keys = Box.keygen();

		return {
			sk: keys.privateKey,
			pk: keys.publicKey,
		};
	};

	/**
	 * Create the identity of the user, which will be used throughout the OPAQUE
	 * process.
	 *
	 * This is a Callisto-specific modification of OPAQUE.
	 *
	 * @param username Original username provided by the user.
	 * @param password Original password provided by the user.
	 * @param salt     Environment-specific salt to use.
	 *
	 * @returns        Compiled identity, and an index suitable for lookups.
	 */
	export const makeIdentity = (
		username: string,
		password: string,
		salt: string,
	): Identity => {
		const combined = `${username}+${password}`;
		const saltHash = sodium.crypto_generichash(
			sodium.crypto_pwhash_SALTBYTES,
			salt,
		);

		const key = sodium.crypto_pwhash(
			sodium.crypto_kdf_KEYBYTES,
			combined,
			saltHash,
			sodium.crypto_pwhash_OPSLIMIT_MODERATE,
			sodium.crypto_pwhash_MEMLIMIT_MODERATE,
			sodium.crypto_pwhash_ALG_ARGON2ID13,
		);
		const index = sodium.crypto_kdf_derive_from_key(
			sodium.crypto_kdf_BYTES_MIN,
			KDF_SUBKEY_INDEX,
			KDF_CONTEXT,
			key,
		);

		return {
			key,
			index: sodium.to_hex(index),
		};
	};

	export const makeUsername = (username: string): string =>
		sodium.to_hex(
			sodium.crypto_generichash(sodium.crypto_kdf_KEYBYTES, username),
		);

	export const makePassword = (password: string, salt: string): Uint8Array => {
		const saltHash = sodium.crypto_generichash(
			sodium.crypto_pwhash_SALTBYTES,
			salt,
		);

		return sodium.crypto_pwhash(
			sodium.crypto_kdf_KEYBYTES,
			password,
			saltHash,
			sodium.crypto_pwhash_OPSLIMIT_MODERATE,
			sodium.crypto_pwhash_MEMLIMIT_MODERATE,
			sodium.crypto_pwhash_ALG_ARGON2ID13,
		);
	};

	/**
	 * Mask the generated identity to get a suitable input to the server-side
	 * OPRF.
	 *
	 * @param identity Compiled output from makeIdentity.
	 */
	export const mask = (identity: Uint8Array): OPRF.Alpha => {
		const identityHash = sodium.crypto_kdf_derive_from_key(
			sodium.crypto_core_ristretto255_HASHBYTES,
			KDF_SUBKEY_POINT,
			KDF_CONTEXT,
			identity,
		);
		const pwPoint = OPRF.makePoint(identityHash);
		return OPRF.mask(pwPoint);
	};

	/**
	 * Unmask the server-side response to get a key usable for encrypting
	 * and decrypting the envelope.
	 *
	 * @param beta Server response.
	 * @param computedMask Mask computed when alpha was generated.
	 *
	 * @return     Key suitable for envelope crypto.
	 */
	export const unmask = (
		beta: Uint8Array,
		computedMask: Uint8Array,
	): Uint8Array => {
		const key = OPRF.unmask(beta, computedMask);

		return sodium.crypto_kdf_derive_from_key(
			sodium.crypto_secretbox_KEYBYTES,
			KDF_SUBKEY_SECRETBOX,
			KDF_CONTEXT,
			key,
		);
	};

	/**
	 * Encrypt envelope data using the password input from the user.
	 *
	 * @param key      Generated key to encrypt with.
	 * @param contents Data to be stored for the user to decrypt.
	 *
	 * @returns        The encrypted envelope, which can be stored as-is.
	 */
	export const encrypt = (
		key: Uint8Array,
		contents: envelopeContents,
	): Uint8Array => Secretbox.encrypt(key, contents);

	/**
	 * Decrypt stored envelope data using the password input from the user.
	 *
	 * @param key       Generated key to decrypt with.
	 * @param encrypted Ciphertext to decrypt.
	 *
	 * @returns         The decrypted envelope data.
	 */
	export const decrypt = (
		key: Uint8Array,
		encrypted: Uint8Array,
	): envelopeContents => Secretbox.decrypt(encrypted, key) as envelopeContents;

	/**
	 * Generate a backup code that the user can use to restore access to their
	 * account.
	 *
	 * @param username Username that should be kept for the new password.
	 * @param input    Envelope to encrypt with the code.
	 * @param salt     Environment-specific salt to use.
	 *
	 * @returns        Encrypted envelope, as well as the original code to use.
	 */
	export const createBackup = (
		username: string,
		input: envelopeContents,
		salt: string,
	): Backup => {
		const code = makeCode();
		const saltHash = sodium.crypto_generichash(
			sodium.crypto_pwhash_SALTBYTES,
			salt,
		);

		const key = sodium.crypto_pwhash(
			sodium.crypto_secretbox_KEYBYTES,
			code,
			saltHash,
			sodium.crypto_pwhash_OPSLIMIT_MODERATE,
			sodium.crypto_pwhash_MEMLIMIT_MODERATE,
			sodium.crypto_pwhash_ALG_ARGON2ID13,
		);

		const contents: BackupContents = {
			username,
			keys: input,
		};
		const encrypted = Secretbox.encrypt(key, contents);

		return {
			encrypted,
			code,
		};
	};

	/**
	 * Decrypts a backup using a provided code.
	 *
	 * @param encrypted Ciphertext fetched from the server.
	 * @param code      Original code handed to the user at backup creation.
	 * @param salt      Environment-specific salt to use.
	 *
	 * @returns         Envelope and original username for the user.
	 */
	export const decryptBackup = (
		encrypted: Uint8Array,
		code: string,
		salt: string,
	): BackupContents => {
		const saltHash = sodium.crypto_generichash(
			sodium.crypto_pwhash_SALTBYTES,
			salt,
		);

		const key = sodium.crypto_pwhash(
			sodium.crypto_secretbox_KEYBYTES,
			code,
			saltHash,
			sodium.crypto_pwhash_OPSLIMIT_MODERATE,
			sodium.crypto_pwhash_MEMLIMIT_MODERATE,
			sodium.crypto_pwhash_ALG_ARGON2ID13,
		);

		return Secretbox.decrypt(encrypted, key) as BackupContents;
	};
}
