/* eslint-disable react/react-in-jsx-scope */
import { ComponentChildren, h } from "preact";
import { useEffect } from "preact/hooks";
import { CompareFunction, dlocEntry, locEntry } from "../data";

interface SortableHeaderProps {
	children: ComponentChildren;
	compare: (a: dlocEntry & locEntry, b: dlocEntry & locEntry) => number;
	doSort: boolean;
	onClick: () => void;
	onSort: () => void;
	sort: (comparer: CompareFunction) => void;
	sortAsc: boolean;
}

export const SortableHeader = ({
	children,
	compare,
	doSort,
	onClick,
	onSort,
	sort,
	sortAsc,
}: SortableHeaderProps): JSX.Element => {
	useEffect(() => {
		if (doSort) {
			const sortingFunction = (
				a: dlocEntry & locEntry,
				b: dlocEntry & locEntry,
			) => (sortAsc ? compare(a, b) : -1 * compare(a, b));
			sort(sortingFunction);
			onSort();
		}
	}, [sortAsc]);

	return (
		<th>
			<a
				className="hover"
				onClick={() => {
					onClick();
				}}
			>
				{children}&nbsp;
				{doSort && sortAsc && (
					<img
						src={require("../assets/icons/sort-asc.svg") as string}
						alt="sort ascending"
						height="12"
					/>
				)}
				{doSort && !sortAsc && (
					<img
						src={require("../assets/icons/sort-desc.svg") as string}
						alt="sort descending"
						height="12"
					/>
				)}
			</a>
		</th>
	);
};
