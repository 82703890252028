import sodium from "libsodium-wrappers-sumo";
import { autodecode, autoencode } from "../../lib/format";

/**
 * Provides a standard way of encrypting/decrypting payloads from authorized
 * users in the system.
 */
export module Payload {
	/**
	 * Encrypts data to a recipient key.
	 *
	 * @param input        Raw object to encode.
	 * @param recipientKey Public key of recipient, to encrypt to.
	 * @param secretKey    Secret key of sender.
	 *
	 * @returns            Encrypted buffer to send to recipient.
	 */
	export const encrypt = (
		// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
		input: any,
		recipientKey: Uint8Array,
		secretKey: Uint8Array,
	): Uint8Array => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const o = autoencode(input);
		const plaintext = JSON.stringify(o);
		const outputNonce = sodium.randombytes_buf(sodium.crypto_box_NONCEBYTES);
		const outputCipher = sodium.crypto_box_easy(
			plaintext,
			outputNonce,
			recipientKey,
			secretKey,
		);

		const combined = new Uint8Array(outputNonce.length + outputCipher.length);
		combined.set(outputNonce, 0);
		combined.set(outputCipher, outputNonce.length);

		return combined;
	};

	/**
	 * Decrypts a payload from a sender.
	 *
	 * @param payload   Encrypted msgpack data from the wire.
	 * @param senderKey Public key of the sender; will be used to verify.
	 * @param secretKey Secret key of recipient.
	 *
	 * @returns         Original object encrypted by the sender.
	 */
	export const decrypt = (
		payload: Uint8Array,
		senderKey: Uint8Array,
		secretKey: Uint8Array,
	): any => {
		const nonce = payload.slice(0, sodium.crypto_box_NONCEBYTES);
		const ciphertext = payload.slice(
			sodium.crypto_box_NONCEBYTES,
			payload.length,
		);

		// TODO: error handling here!
		const message = sodium.crypto_box_open_easy(
			ciphertext,
			nonce,
			senderKey,
			secretKey,
		);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const e = JSON.parse(Buffer.from(message).toString());
		return autodecode(e);
	};

	export const decryptToString = (
		payload: Uint8Array,
		senderKey: Uint8Array,
		secretKey: Uint8Array,
	): string => {
		const nonce = payload.slice(0, sodium.crypto_box_NONCEBYTES);
		const ciphertext = payload.slice(
			sodium.crypto_box_NONCEBYTES,
			payload.length,
		);

		// TODO: error handling here!
		const message = sodium.crypto_box_open_easy(
			ciphertext,
			nonce,
			senderKey,
			secretKey,
		);

		return Buffer.from(message).toString();
	};

	export const encryptBasic = (
		// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
		input: any,
		recipientKey: Uint8Array,
		secretKey: Uint8Array,
	): Uint8Array => {
		const plaintext = JSON.stringify(input);
		const outputNonce = sodium.randombytes_buf(sodium.crypto_box_NONCEBYTES);
		const outputCipher = sodium.crypto_box_easy(
			plaintext,
			outputNonce,
			recipientKey,
			secretKey,
		);

		const combined = new Uint8Array(outputNonce.length + outputCipher.length);
		combined.set(outputNonce, 0);
		combined.set(outputCipher, outputNonce.length);

		return combined;
	};

	export const decryptBasic = (
		payload: Uint8Array,
		senderKey: Uint8Array,
		secretKey: Uint8Array,
	): any => {
		const nonce = payload.slice(0, sodium.crypto_box_NONCEBYTES);
		const ciphertext = payload.slice(
			sodium.crypto_box_NONCEBYTES,
			payload.length,
		);

		// TODO: error handling here!
		const message = sodium.crypto_box_open_easy(
			ciphertext,
			nonce,
			senderKey,
			secretKey,
		);

		return JSON.parse(Buffer.from(message).toString());
	};
}
