import * as t from "io-ts";
import { isRight } from "fp-ts/These";
import { PathReporter } from "io-ts/PathReporter";
import sodium from "libsodium-wrappers-sumo";
import {
	adminUserData,
	adminUserDataConstructor,
	assignmentData,
	assignmentDataConstructor,
	CaseStatus,
	closeOutQuestionnaire,
	closeOutQuestionnaireConstructor,
	dlocUserData,
	dlocUserDataConstructor,
	encryptedBox,
	encryptedBoxConstructor,
	legalNotes,
	legalNotesConstructor,
	locUserData,
	locUserDataConstructor,
	profileData,
	profileDataConstructor,
	SurveyResponse,
	SurveyResponseConstructor,
	userData,
	userDataConstructor,
	versionedKeyBox,
	versionedKeyBoxConstructor,
} from "../../../lib/data";
import { autodecode, autoencode } from "../../../lib/format";

declare let userDataConstruct: userDataConstructor;
declare let dlocUserDataConstruct: dlocUserDataConstructor;
declare let locUserDataConstruct: locUserDataConstructor;
declare let adminUserDataConstruct: adminUserDataConstructor;
declare let closeOutQuestionnaireConstruct: closeOutQuestionnaireConstructor;
declare let surveyResponseConstruct: SurveyResponseConstructor;
declare let assignmentDataConstruct: assignmentDataConstructor;
declare let versionedKeyBoxConstruct: versionedKeyBoxConstructor;
declare let encryptedBoxConstruct: encryptedBoxConstructor;
declare let legalNotesConstruct: legalNotesConstructor;
declare let profileDataConstruct: profileDataConstructor;

/**
 * assignmentData
 */
export const tAssignmentData = new t.Type<assignmentData, string>(
	"AssignmentData",
	(u: unknown): u is assignmentData =>
		u !== null && u instanceof assignmentDataConstruct,
	(u, c) => {
		if (typeof u !== "string") {
			return t.failure(u, c);
		}

		try {
			const data: assignmentData = autodecode(JSON.parse(u)) as assignmentData;
			return t.success(data);
		} catch {
			return t.failure(u, c);
		}
	},
	(data) => JSON.stringify(autoencode(data)),
);

/**
 * closeOutQuestionnaire
 */
export const tCloseOutQuestionnaire = new t.Type<closeOutQuestionnaire, string>(
	"CloseOutQuestionnaire",
	(u: unknown): u is closeOutQuestionnaire =>
		u !== null && u instanceof closeOutQuestionnaireConstruct,
	(u, c) => {
		if (typeof u !== "string") {
			return t.failure(u, c);
		}

		try {
			const data: closeOutQuestionnaire = autodecode(
				JSON.parse(u),
			) as closeOutQuestionnaire;
			return t.success(data);
		} catch {
			return t.failure(u, c);
		}
	},
	(data) => JSON.stringify(autoencode(data)),
);

/**
 * SurveyResponse
 */
export const tSurveyResponse = new t.Type<SurveyResponse, string>(
	"SurveyResponse",
	(u: unknown): u is SurveyResponse =>
		u !== null && u instanceof surveyResponseConstruct,
	(u, c) => {
		if (typeof u !== "string") {
			return t.failure(u, c);
		}

		try {
			const data: SurveyResponse = autodecode(JSON.parse(u)) as SurveyResponse;
			return t.success(data);
		} catch {
			return t.failure(u, c);
		}
	},
	(data) => JSON.stringify(autoencode(data)),
);

/**
 * dlocUserData
 */
export const tDlocUserData = new t.Type<dlocUserData, string>(
	"DlocUserData",
	(u: unknown): u is dlocUserData =>
		u !== null && u instanceof dlocUserDataConstruct,
	(u, c) => {
		if (typeof u !== "string") {
			return t.failure(u, c);
		}

		try {
			const data: dlocUserData = autodecode(JSON.parse(u)) as dlocUserData;
			return t.success(data);
		} catch {
			return t.failure(u, c);
		}
	},
	(data) => JSON.stringify(autoencode(data)),
);

/**
 * locUserData
 */
export const tLocUserData = new t.Type<locUserData, string>(
	"DlocUserData",
	(u: unknown): u is locUserData =>
		u !== null && u instanceof locUserDataConstruct,
	(u, c) => {
		if (typeof u !== "string") {
			return t.failure(u, c);
		}

		try {
			const data: locUserData = autodecode(JSON.parse(u)) as locUserData;
			return t.success(data);
		} catch {
			return t.failure(u, c);
		}
	},
	(data) => JSON.stringify(autoencode(data)),
);

/**
 * adminUserData
 */
export const tAdminUserData = new t.Type<adminUserData, string>(
	"DlocUserData",
	(u: unknown): u is adminUserData =>
		u !== null && u instanceof adminUserDataConstruct,
	(u, c) => {
		if (typeof u !== "string") {
			return t.failure(u, c);
		}

		try {
			const data: adminUserData = autodecode(JSON.parse(u)) as adminUserData;
			return t.success(data);
		} catch {
			return t.failure(u, c);
		}
	},
	(data) => JSON.stringify(autoencode(data)),
);

/**
 * userData
 */
export const tUserData = new t.Type<userData, string>(
	"UserData",
	(u: unknown): u is userData => u !== null && u instanceof userDataConstruct,
	(u, c) => {
		if (typeof u !== "string") {
			return t.failure(u, c);
		}

		try {
			const data: userData = autodecode(JSON.parse(u)) as userData;
			return t.success(data);
		} catch {
			return t.failure(u, c);
		}
	},
	(data) => JSON.stringify(autoencode(data)),
);

/**
 * profileData
 */
export const tProfileData = new t.Type<profileData, string>(
	"ProfileData",
	(u: unknown): u is profileData =>
		u !== null && u instanceof profileDataConstruct,
	(u, c) => {
		if (typeof u !== "string") {
			return t.failure(u, c);
		}

		try {
			const data: profileData = autodecode(JSON.parse(u)) as profileData;
			return t.success(data);
		} catch {
			return t.failure(u, c);
		}
	},
	(data) => JSON.stringify(autoencode(data)),
);

/**
 * Notes taken by an LOC about a case
 */
export const tLegalNotes = new t.Type<legalNotes, string>(
	"LegalNotes",
	(u: unknown): u is legalNotes =>
		u != null && u instanceof legalNotesConstruct,
	(u, c) => {
		if (typeof u !== "string") {
			return t.failure(u, c);
		}

		try {
			const data: legalNotes = autodecode(JSON.parse(u)) as legalNotes;
			return t.success(data);
		} catch {
			return t.failure(u, c);
		}
	},
	(data) => JSON.stringify(autoencode(data)),
);

/**
 * An encrypted item with key version
 */
export const tEncryptedBox = new t.Type<encryptedBox, string>(
	"EncryptedItemWithKeyVersion",
	(u: unknown): u is encryptedBox =>
		u != null && u instanceof encryptedBoxConstruct,
	(u, c) => {
		if (typeof u !== "string") {
			return t.failure(u, c);
		}

		try {
			const data: encryptedBox = autodecode(JSON.parse(u)) as encryptedBox;
			return t.success(data);
		} catch {
			return t.failure(u, c);
		}
	},
	(data) => JSON.stringify(autoencode(data)),
);

/**
 * A versioned key with version
 */
export const tVersionedKeyBox = new t.Type<versionedKeyBox, string>(
	"KeyWithVersion",
	(u: unknown): u is versionedKeyBox =>
		u != null && u instanceof versionedKeyBoxConstruct,
	(u, c) => {
		if (typeof u !== "string") {
			return t.failure(u, c);
		}

		try {
			const data: versionedKeyBox = autodecode(
				JSON.parse(u),
			) as versionedKeyBox;
			return t.success(data);
		} catch {
			return t.failure(u, c);
		}
	},
	(data) => JSON.stringify(autoencode(data)),
);

/**
 * A key
 */
export const tKey = new t.Type<Uint8Array, string>(
	"EncryptionKey",
	(u: unknown): u is Uint8Array => u !== null && u instanceof Uint8Array,
	(u, c) => {
		if (typeof u !== "string") {
			return t.failure(u, c);
		}

		try {
			const data: Uint8Array = autodecode(JSON.parse(u)) as Uint8Array;
			return t.success(data);
		} catch {
			return t.failure(u, c);
		}
	},
	(data) => JSON.stringify(autoencode(data)),
);

/**
 * Simple hex byte string
 */
export const tHexBytes = new t.Type<Uint8Array, string>(
	"HexBytes",
	(u: unknown): u is Uint8Array => u !== null && u instanceof Uint8Array,
	(u, c) => {
		if (typeof u !== "string") {
			return t.failure(u, c);
		}

		const d = sodium.from_hex(u);

		return t.success(d);
	},
	(a) => sodium.to_hex(a),
);

/**
 * Byte string with "hex$" as a prefix
 */
export const tSodiumBytes = new t.Type<Uint8Array, string, unknown>(
	"SodiumBytes",
	(u: unknown): u is Uint8Array => u !== null && u instanceof Uint8Array,
	(u, c) => {
		if (typeof u !== "string" || !u.startsWith("hex$")) {
			return t.failure(u, c);
		}

		const d = sodium.from_hex(u.substr(4));

		return t.success(d);
	},
	(a) => `hex$${sodium.to_hex(a)}`,
);

/**
 * Date string with date$ prefix
 */
export const tSodiumDate = new t.Type<Date, string, unknown>(
	"SodiumDate",
	(u: unknown): u is Date => u !== null && u instanceof Date,
	(u, c) => {
		if (typeof u !== "string" || !u.startsWith("date$")) {
			return t.failure(u, c);
		}

		const d = new Date(u.substr(5));
		if (Number.isNaN(d.getTime())) {
			return t.failure(u, c);
		}

		return t.success(d);
	},
	(a) => `date$${a.toUTCString()}`,
);

export const tCaseStatus = new t.Type<CaseStatus, string, unknown>(
	"CaseStatus",
	(u: unknown): u is CaseStatus => u !== null && typeof u === "string",
	(u, c) => {
		if (typeof u !== "string") {
			return t.failure(u, c);
		}

		if (!Object.values(CaseStatus).includes(u as CaseStatus)) {
			return t.failure(u, c);
		}

		return t.success(u as CaseStatus);
	},
	(a) => String(a),
);

export const iotsValidateThrow = <A>(
	validator: t.Type<A, unknown>,
	input: unknown,
): A => {
	const result = validator.decode(input);

	if (!isRight(result)) {
		throw new Error(PathReporter.report(result).join(", "));
	}

	return result.right;
};
