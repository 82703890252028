import {
	ConfigServiceClient,
	GetCurrentFlagsRequest,
} from "../../../core/if/config.gen";
import {
	GetTokenRequest,
	TokenServiceClient,
} from "../../../core/if/token.gen";
import { LogoutRequest, UserServiceClient } from "../../../core/if/user.gen";
import { RecoveryClient } from "../../../src/recoveryserver/client";

export abstract class VaultClient {
	protected flagsFetched: Promise<void>;
	protected baseURL: string;
	protected credentials: Credentials;

	private cachedFlags: Map<string, string>;

	constructor(baseURL = "") {
		this.baseURL = baseURL;

		// Always fetch the current flags at startup so that derived classes can use them.
		const flagsClient = new ConfigServiceClient(this.baseURL);
		this.flagsFetched = flagsClient
			.get_current_flags(new GetCurrentFlagsRequest(), null)
			.then((value) => {
				this.cachedFlags = value.flags;
			});
	}

	// Configuration Flags
	public async getFlag(key: string): Promise<string> {
		await this.flagsFetched;
		return this.cachedFlags.get(key);
	}

	// Account Recovery
	public async getRecoveryClient(): Promise<RecoveryClient> {
		return new RecoveryClient(
			await this.getFlag("UI_ENV_RECOVERY_ALPHA_URL"),
			await this.getFlag("UI_ENV_RECOVERY_BETA_URL"),
		);
	}

	// Account Management
	public get isLoggedIn(): boolean {
		return this.credentials !== undefined;
	}

	protected setCredentials(c: Credentials) {
		this.credentials = c;
	}

	protected destroyCredentials() {
		this.credentials = undefined;
	}

	async logout(): Promise<void> {
		// Calling the service to delete the CALLISTO_AUTH token is now necessary, since client-side JS can't do it.
		const client = new UserServiceClient(this.baseURL);
		await client.logout(new LogoutRequest(), null);

		this.destroyCredentials();
	}

	// Tokens
	async getToken(operation?: string): Promise<string> {
		const client = new TokenServiceClient(this.baseURL);
		const resp = await client.get_token(
			new GetTokenRequest({ operation }),
			null,
		);

		return resp.token;
	}

	// Special
	public async isReopened(): Promise<boolean> {
		return (await this.getFlag("UI_ENV_2024_REOPEN")) === "1";
	}
}

export interface Credentials {
	readonly pk: Uint8Array;

	encrypt<T>(plaintext: T): Uint8Array;
	decrypt<T>(ciphertext: Uint8Array): T;

	sign(plaintext: Uint8Array): Uint8Array;
	verify(signature: Uint8Array): boolean;
}

export class InMemoryCredentials implements Credentials {
	private readonly sk: Uint8Array;
	readonly pk: Uint8Array;

	constructor(sk: Uint8Array, pk: Uint8Array) {
		this.pk = pk;
		this.sk = sk;
	}

	encrypt<T>(plaintext: T): Uint8Array {
		return new Uint8Array();
	}
	decrypt<T>(ciphertext: Uint8Array): T {
		return {} as T;
	}

	sign(plaintext: Uint8Array): Uint8Array {
		return new Uint8Array();
	}
	verify(signature: Uint8Array): boolean {
		return true;
	}
}
