/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 5.28.3
 * source: recovery.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
import * as grpc_1 from "@grpc/grpc-js";
import * as grpc_web_1 from "grpc-web";
export class OPRFRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        alpha?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("alpha" in data && data.alpha != undefined) {
                this.alpha = data.alpha;
            }
        }
    }
    get alpha() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set alpha(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        alpha?: Uint8Array;
    }): OPRFRequest {
        const message = new OPRFRequest({});
        if (data.alpha != null) {
            message.alpha = data.alpha;
        }
        return message;
    }
    toObject() {
        const data: {
            alpha?: Uint8Array;
        } = {};
        if (this.alpha != null) {
            data.alpha = this.alpha;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.alpha.length)
            writer.writeBytes(1, this.alpha);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OPRFRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OPRFRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.alpha = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): OPRFRequest {
        return OPRFRequest.deserialize(bytes);
    }
}
export class OPRFResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        beta?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("beta" in data && data.beta != undefined) {
                this.beta = data.beta;
            }
        }
    }
    get beta() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set beta(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        beta?: Uint8Array;
    }): OPRFResponse {
        const message = new OPRFResponse({});
        if (data.beta != null) {
            message.beta = data.beta;
        }
        return message;
    }
    toObject() {
        const data: {
            beta?: Uint8Array;
        } = {};
        if (this.beta != null) {
            data.beta = this.beta;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.beta.length)
            writer.writeBytes(1, this.beta);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): OPRFResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new OPRFResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.beta = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): OPRFResponse {
        return OPRFResponse.deserialize(bytes);
    }
}
export class Setup_POPRFRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        alpha?: Uint8Array;
        n?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("alpha" in data && data.alpha != undefined) {
                this.alpha = data.alpha;
            }
            if ("n" in data && data.n != undefined) {
                this.n = data.n;
            }
        }
    }
    get alpha() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set alpha(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get n() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set n(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        alpha?: Uint8Array;
        n?: Uint8Array;
    }): Setup_POPRFRequest {
        const message = new Setup_POPRFRequest({});
        if (data.alpha != null) {
            message.alpha = data.alpha;
        }
        if (data.n != null) {
            message.n = data.n;
        }
        return message;
    }
    toObject() {
        const data: {
            alpha?: Uint8Array;
            n?: Uint8Array;
        } = {};
        if (this.alpha != null) {
            data.alpha = this.alpha;
        }
        if (this.n != null) {
            data.n = this.n;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.alpha.length)
            writer.writeBytes(1, this.alpha);
        if (this.n.length)
            writer.writeBytes(2, this.n);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Setup_POPRFRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Setup_POPRFRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.alpha = reader.readBytes();
                    break;
                case 2:
                    message.n = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Setup_POPRFRequest {
        return Setup_POPRFRequest.deserialize(bytes);
    }
}
export class Setup_POPRFResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        beta?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("beta" in data && data.beta != undefined) {
                this.beta = data.beta;
            }
        }
    }
    get beta() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set beta(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        beta?: Uint8Array;
    }): Setup_POPRFResponse {
        const message = new Setup_POPRFResponse({});
        if (data.beta != null) {
            message.beta = data.beta;
        }
        return message;
    }
    toObject() {
        const data: {
            beta?: Uint8Array;
        } = {};
        if (this.beta != null) {
            data.beta = this.beta;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.beta.length)
            writer.writeBytes(1, this.beta);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Setup_POPRFResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Setup_POPRFResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.beta = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Setup_POPRFResponse {
        return Setup_POPRFResponse.deserialize(bytes);
    }
}
export class Setup_SaveRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: Uint8Array;
        eE?: Uint8Array;
        kE?: Uint8Array;
        eA?: Uint8Array;
        kA?: Uint8Array;
        n?: Uint8Array;
        pk?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("eE" in data && data.eE != undefined) {
                this.eE = data.eE;
            }
            if ("kE" in data && data.kE != undefined) {
                this.kE = data.kE;
            }
            if ("eA" in data && data.eA != undefined) {
                this.eA = data.eA;
            }
            if ("kA" in data && data.kA != undefined) {
                this.kA = data.kA;
            }
            if ("n" in data && data.n != undefined) {
                this.n = data.n;
            }
            if ("pk" in data && data.pk != undefined) {
                this.pk = data.pk;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set id(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get eE() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set eE(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get kE() {
        return pb_1.Message.getFieldWithDefault(this, 3, new Uint8Array(0)) as Uint8Array;
    }
    set kE(value: Uint8Array) {
        pb_1.Message.setField(this, 3, value);
    }
    get eA() {
        return pb_1.Message.getFieldWithDefault(this, 4, new Uint8Array(0)) as Uint8Array;
    }
    set eA(value: Uint8Array) {
        pb_1.Message.setField(this, 4, value);
    }
    get kA() {
        return pb_1.Message.getFieldWithDefault(this, 5, new Uint8Array(0)) as Uint8Array;
    }
    set kA(value: Uint8Array) {
        pb_1.Message.setField(this, 5, value);
    }
    get n() {
        return pb_1.Message.getFieldWithDefault(this, 6, new Uint8Array(0)) as Uint8Array;
    }
    set n(value: Uint8Array) {
        pb_1.Message.setField(this, 6, value);
    }
    get pk() {
        return pb_1.Message.getFieldWithDefault(this, 7, new Uint8Array(0)) as Uint8Array;
    }
    set pk(value: Uint8Array) {
        pb_1.Message.setField(this, 7, value);
    }
    static fromObject(data: {
        id?: Uint8Array;
        eE?: Uint8Array;
        kE?: Uint8Array;
        eA?: Uint8Array;
        kA?: Uint8Array;
        n?: Uint8Array;
        pk?: Uint8Array;
    }): Setup_SaveRequest {
        const message = new Setup_SaveRequest({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.eE != null) {
            message.eE = data.eE;
        }
        if (data.kE != null) {
            message.kE = data.kE;
        }
        if (data.eA != null) {
            message.eA = data.eA;
        }
        if (data.kA != null) {
            message.kA = data.kA;
        }
        if (data.n != null) {
            message.n = data.n;
        }
        if (data.pk != null) {
            message.pk = data.pk;
        }
        return message;
    }
    toObject() {
        const data: {
            id?: Uint8Array;
            eE?: Uint8Array;
            kE?: Uint8Array;
            eA?: Uint8Array;
            kA?: Uint8Array;
            n?: Uint8Array;
            pk?: Uint8Array;
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.eE != null) {
            data.eE = this.eE;
        }
        if (this.kE != null) {
            data.kE = this.kE;
        }
        if (this.eA != null) {
            data.eA = this.eA;
        }
        if (this.kA != null) {
            data.kA = this.kA;
        }
        if (this.n != null) {
            data.n = this.n;
        }
        if (this.pk != null) {
            data.pk = this.pk;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeBytes(1, this.id);
        if (this.eE.length)
            writer.writeBytes(2, this.eE);
        if (this.kE.length)
            writer.writeBytes(3, this.kE);
        if (this.eA.length)
            writer.writeBytes(4, this.eA);
        if (this.kA.length)
            writer.writeBytes(5, this.kA);
        if (this.n.length)
            writer.writeBytes(6, this.n);
        if (this.pk.length)
            writer.writeBytes(7, this.pk);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Setup_SaveRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Setup_SaveRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readBytes();
                    break;
                case 2:
                    message.eE = reader.readBytes();
                    break;
                case 3:
                    message.kE = reader.readBytes();
                    break;
                case 4:
                    message.eA = reader.readBytes();
                    break;
                case 5:
                    message.kA = reader.readBytes();
                    break;
                case 6:
                    message.n = reader.readBytes();
                    break;
                case 7:
                    message.pk = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Setup_SaveRequest {
        return Setup_SaveRequest.deserialize(bytes);
    }
}
export class Setup_SaveResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): Setup_SaveResponse {
        const message = new Setup_SaveResponse({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Setup_SaveResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Setup_SaveResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Setup_SaveResponse {
        return Setup_SaveResponse.deserialize(bytes);
    }
}
export class Request_StartRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: Uint8Array;
        kOTPE?: Uint8Array;
        mailDomainKey?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("kOTPE" in data && data.kOTPE != undefined) {
                this.kOTPE = data.kOTPE;
            }
            if ("mailDomainKey" in data && data.mailDomainKey != undefined) {
                this.mailDomainKey = data.mailDomainKey;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set id(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get kOTPE() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set kOTPE(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get mailDomainKey() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set mailDomainKey(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        id?: Uint8Array;
        kOTPE?: Uint8Array;
        mailDomainKey?: string;
    }): Request_StartRequest {
        const message = new Request_StartRequest({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.kOTPE != null) {
            message.kOTPE = data.kOTPE;
        }
        if (data.mailDomainKey != null) {
            message.mailDomainKey = data.mailDomainKey;
        }
        return message;
    }
    toObject() {
        const data: {
            id?: Uint8Array;
            kOTPE?: Uint8Array;
            mailDomainKey?: string;
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.kOTPE != null) {
            data.kOTPE = this.kOTPE;
        }
        if (this.mailDomainKey != null) {
            data.mailDomainKey = this.mailDomainKey;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeBytes(1, this.id);
        if (this.kOTPE.length)
            writer.writeBytes(2, this.kOTPE);
        if (this.mailDomainKey.length)
            writer.writeString(3, this.mailDomainKey);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Request_StartRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Request_StartRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readBytes();
                    break;
                case 2:
                    message.kOTPE = reader.readBytes();
                    break;
                case 3:
                    message.mailDomainKey = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Request_StartRequest {
        return Request_StartRequest.deserialize(bytes);
    }
}
export class Request_StartResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): Request_StartResponse {
        const message = new Request_StartResponse({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Request_StartResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Request_StartResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Request_StartResponse {
        return Request_StartResponse.deserialize(bytes);
    }
}
export class Recovery_StartRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        token?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
        }
    }
    get token() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set token(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        token?: string;
    }): Recovery_StartRequest {
        const message = new Recovery_StartRequest({});
        if (data.token != null) {
            message.token = data.token;
        }
        return message;
    }
    toObject() {
        const data: {
            token?: string;
        } = {};
        if (this.token != null) {
            data.token = this.token;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.token.length)
            writer.writeString(1, this.token);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Recovery_StartRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Recovery_StartRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.token = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Recovery_StartRequest {
        return Recovery_StartRequest.deserialize(bytes);
    }
}
export class Recovery_StartResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        transaction?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("transaction" in data && data.transaction != undefined) {
                this.transaction = data.transaction;
            }
        }
    }
    get transaction() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set transaction(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        transaction?: string;
    }): Recovery_StartResponse {
        const message = new Recovery_StartResponse({});
        if (data.transaction != null) {
            message.transaction = data.transaction;
        }
        return message;
    }
    toObject() {
        const data: {
            transaction?: string;
        } = {};
        if (this.transaction != null) {
            data.transaction = this.transaction;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.transaction.length)
            writer.writeString(1, this.transaction);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Recovery_StartResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Recovery_StartResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.transaction = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Recovery_StartResponse {
        return Recovery_StartResponse.deserialize(bytes);
    }
}
export class Recovery_ValidateTokenRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        token?: string;
        transaction?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
            if ("transaction" in data && data.transaction != undefined) {
                this.transaction = data.transaction;
            }
        }
    }
    get token() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set token(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get transaction() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set transaction(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        token?: string;
        transaction?: string;
    }): Recovery_ValidateTokenRequest {
        const message = new Recovery_ValidateTokenRequest({});
        if (data.token != null) {
            message.token = data.token;
        }
        if (data.transaction != null) {
            message.transaction = data.transaction;
        }
        return message;
    }
    toObject() {
        const data: {
            token?: string;
            transaction?: string;
        } = {};
        if (this.token != null) {
            data.token = this.token;
        }
        if (this.transaction != null) {
            data.transaction = this.transaction;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.token.length)
            writer.writeString(1, this.token);
        if (this.transaction.length)
            writer.writeString(2, this.transaction);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Recovery_ValidateTokenRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Recovery_ValidateTokenRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.token = reader.readString();
                    break;
                case 2:
                    message.transaction = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Recovery_ValidateTokenRequest {
        return Recovery_ValidateTokenRequest.deserialize(bytes);
    }
}
export class Recovery_ValidateTokenResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        questions?: string[];
        eA?: Uint8Array;
        kA1?: Uint8Array;
        kA2?: Uint8Array;
        m?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [1], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("questions" in data && data.questions != undefined) {
                this.questions = data.questions;
            }
            if ("eA" in data && data.eA != undefined) {
                this.eA = data.eA;
            }
            if ("kA1" in data && data.kA1 != undefined) {
                this.kA1 = data.kA1;
            }
            if ("kA2" in data && data.kA2 != undefined) {
                this.kA2 = data.kA2;
            }
            if ("m" in data && data.m != undefined) {
                this.m = data.m;
            }
        }
    }
    get questions() {
        return pb_1.Message.getFieldWithDefault(this, 1, []) as string[];
    }
    set questions(value: string[]) {
        pb_1.Message.setField(this, 1, value);
    }
    get eA() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set eA(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get kA1() {
        return pb_1.Message.getFieldWithDefault(this, 3, new Uint8Array(0)) as Uint8Array;
    }
    set kA1(value: Uint8Array) {
        pb_1.Message.setField(this, 3, value);
    }
    get kA2() {
        return pb_1.Message.getFieldWithDefault(this, 4, new Uint8Array(0)) as Uint8Array;
    }
    set kA2(value: Uint8Array) {
        pb_1.Message.setField(this, 4, value);
    }
    get m() {
        return pb_1.Message.getFieldWithDefault(this, 5, new Uint8Array(0)) as Uint8Array;
    }
    set m(value: Uint8Array) {
        pb_1.Message.setField(this, 5, value);
    }
    static fromObject(data: {
        questions?: string[];
        eA?: Uint8Array;
        kA1?: Uint8Array;
        kA2?: Uint8Array;
        m?: Uint8Array;
    }): Recovery_ValidateTokenResponse {
        const message = new Recovery_ValidateTokenResponse({});
        if (data.questions != null) {
            message.questions = data.questions;
        }
        if (data.eA != null) {
            message.eA = data.eA;
        }
        if (data.kA1 != null) {
            message.kA1 = data.kA1;
        }
        if (data.kA2 != null) {
            message.kA2 = data.kA2;
        }
        if (data.m != null) {
            message.m = data.m;
        }
        return message;
    }
    toObject() {
        const data: {
            questions?: string[];
            eA?: Uint8Array;
            kA1?: Uint8Array;
            kA2?: Uint8Array;
            m?: Uint8Array;
        } = {};
        if (this.questions != null) {
            data.questions = this.questions;
        }
        if (this.eA != null) {
            data.eA = this.eA;
        }
        if (this.kA1 != null) {
            data.kA1 = this.kA1;
        }
        if (this.kA2 != null) {
            data.kA2 = this.kA2;
        }
        if (this.m != null) {
            data.m = this.m;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.questions.length)
            writer.writeRepeatedString(1, this.questions);
        if (this.eA.length)
            writer.writeBytes(2, this.eA);
        if (this.kA1.length)
            writer.writeBytes(3, this.kA1);
        if (this.kA2.length)
            writer.writeBytes(4, this.kA2);
        if (this.m.length)
            writer.writeBytes(5, this.m);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Recovery_ValidateTokenResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Recovery_ValidateTokenResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    pb_1.Message.addToRepeatedField(message, 1, reader.readString());
                    break;
                case 2:
                    message.eA = reader.readBytes();
                    break;
                case 3:
                    message.kA1 = reader.readBytes();
                    break;
                case 4:
                    message.kA2 = reader.readBytes();
                    break;
                case 5:
                    message.m = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Recovery_ValidateTokenResponse {
        return Recovery_ValidateTokenResponse.deserialize(bytes);
    }
}
export class Recovery_POPRFRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        token?: string;
        transaction?: string;
        alpha?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("token" in data && data.token != undefined) {
                this.token = data.token;
            }
            if ("transaction" in data && data.transaction != undefined) {
                this.transaction = data.transaction;
            }
            if ("alpha" in data && data.alpha != undefined) {
                this.alpha = data.alpha;
            }
        }
    }
    get token() {
        return pb_1.Message.getFieldWithDefault(this, 1, "") as string;
    }
    set token(value: string) {
        pb_1.Message.setField(this, 1, value);
    }
    get transaction() {
        return pb_1.Message.getFieldWithDefault(this, 2, "") as string;
    }
    set transaction(value: string) {
        pb_1.Message.setField(this, 2, value);
    }
    get alpha() {
        return pb_1.Message.getFieldWithDefault(this, 3, new Uint8Array(0)) as Uint8Array;
    }
    set alpha(value: Uint8Array) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        token?: string;
        transaction?: string;
        alpha?: Uint8Array;
    }): Recovery_POPRFRequest {
        const message = new Recovery_POPRFRequest({});
        if (data.token != null) {
            message.token = data.token;
        }
        if (data.transaction != null) {
            message.transaction = data.transaction;
        }
        if (data.alpha != null) {
            message.alpha = data.alpha;
        }
        return message;
    }
    toObject() {
        const data: {
            token?: string;
            transaction?: string;
            alpha?: Uint8Array;
        } = {};
        if (this.token != null) {
            data.token = this.token;
        }
        if (this.transaction != null) {
            data.transaction = this.transaction;
        }
        if (this.alpha != null) {
            data.alpha = this.alpha;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.token.length)
            writer.writeString(1, this.token);
        if (this.transaction.length)
            writer.writeString(2, this.transaction);
        if (this.alpha.length)
            writer.writeBytes(3, this.alpha);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Recovery_POPRFRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Recovery_POPRFRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.token = reader.readString();
                    break;
                case 2:
                    message.transaction = reader.readString();
                    break;
                case 3:
                    message.alpha = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Recovery_POPRFRequest {
        return Recovery_POPRFRequest.deserialize(bytes);
    }
}
export class Recovery_POPRFResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        beta?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("beta" in data && data.beta != undefined) {
                this.beta = data.beta;
            }
        }
    }
    get beta() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set beta(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    static fromObject(data: {
        beta?: Uint8Array;
    }): Recovery_POPRFResponse {
        const message = new Recovery_POPRFResponse({});
        if (data.beta != null) {
            message.beta = data.beta;
        }
        return message;
    }
    toObject() {
        const data: {
            beta?: Uint8Array;
        } = {};
        if (this.beta != null) {
            data.beta = this.beta;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.beta.length)
            writer.writeBytes(1, this.beta);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Recovery_POPRFResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Recovery_POPRFResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.beta = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Recovery_POPRFResponse {
        return Recovery_POPRFResponse.deserialize(bytes);
    }
}
export class Delete_Request extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: Uint8Array;
        signature?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("signature" in data && data.signature != undefined) {
                this.signature = data.signature;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set id(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get signature() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set signature(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    static fromObject(data: {
        id?: Uint8Array;
        signature?: Uint8Array;
    }): Delete_Request {
        const message = new Delete_Request({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.signature != null) {
            message.signature = data.signature;
        }
        return message;
    }
    toObject() {
        const data: {
            id?: Uint8Array;
            signature?: Uint8Array;
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.signature != null) {
            data.signature = this.signature;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeBytes(1, this.id);
        if (this.signature.length)
            writer.writeBytes(2, this.signature);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Delete_Request {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Delete_Request();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readBytes();
                    break;
                case 2:
                    message.signature = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Delete_Request {
        return Delete_Request.deserialize(bytes);
    }
}
export class Delete_Response extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): Delete_Response {
        const message = new Delete_Response({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Delete_Response {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Delete_Response();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Delete_Response {
        return Delete_Response.deserialize(bytes);
    }
}
export class Delete_By_Proxy_Request extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: Uint8Array;
        signature?: Uint8Array;
        userId?: string;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("signature" in data && data.signature != undefined) {
                this.signature = data.signature;
            }
            if ("userId" in data && data.userId != undefined) {
                this.userId = data.userId;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set id(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get signature() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set signature(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get userId() {
        return pb_1.Message.getFieldWithDefault(this, 3, "") as string;
    }
    set userId(value: string) {
        pb_1.Message.setField(this, 3, value);
    }
    static fromObject(data: {
        id?: Uint8Array;
        signature?: Uint8Array;
        userId?: string;
    }): Delete_By_Proxy_Request {
        const message = new Delete_By_Proxy_Request({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.signature != null) {
            message.signature = data.signature;
        }
        if (data.userId != null) {
            message.userId = data.userId;
        }
        return message;
    }
    toObject() {
        const data: {
            id?: Uint8Array;
            signature?: Uint8Array;
            userId?: string;
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.signature != null) {
            data.signature = this.signature;
        }
        if (this.userId != null) {
            data.userId = this.userId;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeBytes(1, this.id);
        if (this.signature.length)
            writer.writeBytes(2, this.signature);
        if (this.userId.length)
            writer.writeString(3, this.userId);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Delete_By_Proxy_Request {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Delete_By_Proxy_Request();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readBytes();
                    break;
                case 2:
                    message.signature = reader.readBytes();
                    break;
                case 3:
                    message.userId = reader.readString();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Delete_By_Proxy_Request {
        return Delete_By_Proxy_Request.deserialize(bytes);
    }
}
export class Delete_By_Proxy_Response extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): Delete_By_Proxy_Response {
        const message = new Delete_By_Proxy_Response({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Delete_By_Proxy_Response {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Delete_By_Proxy_Response();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Delete_By_Proxy_Response {
        return Delete_By_Proxy_Response.deserialize(bytes);
    }
}
export class Update_Request extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        id?: Uint8Array;
        newId?: Uint8Array;
        eE?: Uint8Array;
        kE?: Uint8Array;
        eA?: Uint8Array;
        kA?: Uint8Array;
        signature?: Uint8Array;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("id" in data && data.id != undefined) {
                this.id = data.id;
            }
            if ("newId" in data && data.newId != undefined) {
                this.newId = data.newId;
            }
            if ("eE" in data && data.eE != undefined) {
                this.eE = data.eE;
            }
            if ("kE" in data && data.kE != undefined) {
                this.kE = data.kE;
            }
            if ("eA" in data && data.eA != undefined) {
                this.eA = data.eA;
            }
            if ("kA" in data && data.kA != undefined) {
                this.kA = data.kA;
            }
            if ("signature" in data && data.signature != undefined) {
                this.signature = data.signature;
            }
        }
    }
    get id() {
        return pb_1.Message.getFieldWithDefault(this, 1, new Uint8Array(0)) as Uint8Array;
    }
    set id(value: Uint8Array) {
        pb_1.Message.setField(this, 1, value);
    }
    get newId() {
        return pb_1.Message.getFieldWithDefault(this, 2, new Uint8Array(0)) as Uint8Array;
    }
    set newId(value: Uint8Array) {
        pb_1.Message.setField(this, 2, value);
    }
    get eE() {
        return pb_1.Message.getFieldWithDefault(this, 3, new Uint8Array(0)) as Uint8Array;
    }
    set eE(value: Uint8Array) {
        pb_1.Message.setField(this, 3, value);
    }
    get kE() {
        return pb_1.Message.getFieldWithDefault(this, 4, new Uint8Array(0)) as Uint8Array;
    }
    set kE(value: Uint8Array) {
        pb_1.Message.setField(this, 4, value);
    }
    get eA() {
        return pb_1.Message.getFieldWithDefault(this, 5, new Uint8Array(0)) as Uint8Array;
    }
    set eA(value: Uint8Array) {
        pb_1.Message.setField(this, 5, value);
    }
    get kA() {
        return pb_1.Message.getFieldWithDefault(this, 6, new Uint8Array(0)) as Uint8Array;
    }
    set kA(value: Uint8Array) {
        pb_1.Message.setField(this, 6, value);
    }
    get signature() {
        return pb_1.Message.getFieldWithDefault(this, 7, new Uint8Array(0)) as Uint8Array;
    }
    set signature(value: Uint8Array) {
        pb_1.Message.setField(this, 7, value);
    }
    static fromObject(data: {
        id?: Uint8Array;
        newId?: Uint8Array;
        eE?: Uint8Array;
        kE?: Uint8Array;
        eA?: Uint8Array;
        kA?: Uint8Array;
        signature?: Uint8Array;
    }): Update_Request {
        const message = new Update_Request({});
        if (data.id != null) {
            message.id = data.id;
        }
        if (data.newId != null) {
            message.newId = data.newId;
        }
        if (data.eE != null) {
            message.eE = data.eE;
        }
        if (data.kE != null) {
            message.kE = data.kE;
        }
        if (data.eA != null) {
            message.eA = data.eA;
        }
        if (data.kA != null) {
            message.kA = data.kA;
        }
        if (data.signature != null) {
            message.signature = data.signature;
        }
        return message;
    }
    toObject() {
        const data: {
            id?: Uint8Array;
            newId?: Uint8Array;
            eE?: Uint8Array;
            kE?: Uint8Array;
            eA?: Uint8Array;
            kA?: Uint8Array;
            signature?: Uint8Array;
        } = {};
        if (this.id != null) {
            data.id = this.id;
        }
        if (this.newId != null) {
            data.newId = this.newId;
        }
        if (this.eE != null) {
            data.eE = this.eE;
        }
        if (this.kE != null) {
            data.kE = this.kE;
        }
        if (this.eA != null) {
            data.eA = this.eA;
        }
        if (this.kA != null) {
            data.kA = this.kA;
        }
        if (this.signature != null) {
            data.signature = this.signature;
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (this.id.length)
            writer.writeBytes(1, this.id);
        if (this.newId.length)
            writer.writeBytes(2, this.newId);
        if (this.eE.length)
            writer.writeBytes(3, this.eE);
        if (this.kE.length)
            writer.writeBytes(4, this.kE);
        if (this.eA.length)
            writer.writeBytes(5, this.eA);
        if (this.kA.length)
            writer.writeBytes(6, this.kA);
        if (this.signature.length)
            writer.writeBytes(7, this.signature);
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Update_Request {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Update_Request();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    message.id = reader.readBytes();
                    break;
                case 2:
                    message.newId = reader.readBytes();
                    break;
                case 3:
                    message.eE = reader.readBytes();
                    break;
                case 4:
                    message.kE = reader.readBytes();
                    break;
                case 5:
                    message.eA = reader.readBytes();
                    break;
                case 6:
                    message.kA = reader.readBytes();
                    break;
                case 7:
                    message.signature = reader.readBytes();
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Update_Request {
        return Update_Request.deserialize(bytes);
    }
}
export class Update_Response extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): Update_Response {
        const message = new Update_Response({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): Update_Response {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new Update_Response();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): Update_Response {
        return Update_Response.deserialize(bytes);
    }
}
export abstract class UnimplementedRecoveryServiceService {
    static definition = {
        oprf: {
            path: "/org.callisto.recovery.v1.RecoveryService/oprf",
            requestStream: false,
            responseStream: false,
            requestSerialize: (message: OPRFRequest) => Buffer.from(message.serialize()),
            requestDeserialize: (bytes: Buffer) => OPRFRequest.deserialize(new Uint8Array(bytes)),
            responseSerialize: (message: OPRFResponse) => Buffer.from(message.serialize()),
            responseDeserialize: (bytes: Buffer) => OPRFResponse.deserialize(new Uint8Array(bytes))
        },
        setup_POPRF: {
            path: "/org.callisto.recovery.v1.RecoveryService/setup_POPRF",
            requestStream: false,
            responseStream: false,
            requestSerialize: (message: Setup_POPRFRequest) => Buffer.from(message.serialize()),
            requestDeserialize: (bytes: Buffer) => Setup_POPRFRequest.deserialize(new Uint8Array(bytes)),
            responseSerialize: (message: Setup_POPRFResponse) => Buffer.from(message.serialize()),
            responseDeserialize: (bytes: Buffer) => Setup_POPRFResponse.deserialize(new Uint8Array(bytes))
        },
        setup_Save: {
            path: "/org.callisto.recovery.v1.RecoveryService/setup_Save",
            requestStream: false,
            responseStream: false,
            requestSerialize: (message: Setup_SaveRequest) => Buffer.from(message.serialize()),
            requestDeserialize: (bytes: Buffer) => Setup_SaveRequest.deserialize(new Uint8Array(bytes)),
            responseSerialize: (message: Setup_SaveResponse) => Buffer.from(message.serialize()),
            responseDeserialize: (bytes: Buffer) => Setup_SaveResponse.deserialize(new Uint8Array(bytes))
        },
        request_Start: {
            path: "/org.callisto.recovery.v1.RecoveryService/request_Start",
            requestStream: false,
            responseStream: false,
            requestSerialize: (message: Request_StartRequest) => Buffer.from(message.serialize()),
            requestDeserialize: (bytes: Buffer) => Request_StartRequest.deserialize(new Uint8Array(bytes)),
            responseSerialize: (message: Request_StartResponse) => Buffer.from(message.serialize()),
            responseDeserialize: (bytes: Buffer) => Request_StartResponse.deserialize(new Uint8Array(bytes))
        },
        recovery_Start: {
            path: "/org.callisto.recovery.v1.RecoveryService/recovery_Start",
            requestStream: false,
            responseStream: false,
            requestSerialize: (message: Recovery_StartRequest) => Buffer.from(message.serialize()),
            requestDeserialize: (bytes: Buffer) => Recovery_StartRequest.deserialize(new Uint8Array(bytes)),
            responseSerialize: (message: Recovery_StartResponse) => Buffer.from(message.serialize()),
            responseDeserialize: (bytes: Buffer) => Recovery_StartResponse.deserialize(new Uint8Array(bytes))
        },
        recovery_ValidateToken: {
            path: "/org.callisto.recovery.v1.RecoveryService/recovery_ValidateToken",
            requestStream: false,
            responseStream: false,
            requestSerialize: (message: Recovery_ValidateTokenRequest) => Buffer.from(message.serialize()),
            requestDeserialize: (bytes: Buffer) => Recovery_ValidateTokenRequest.deserialize(new Uint8Array(bytes)),
            responseSerialize: (message: Recovery_ValidateTokenResponse) => Buffer.from(message.serialize()),
            responseDeserialize: (bytes: Buffer) => Recovery_ValidateTokenResponse.deserialize(new Uint8Array(bytes))
        },
        recovery_POPRF: {
            path: "/org.callisto.recovery.v1.RecoveryService/recovery_POPRF",
            requestStream: false,
            responseStream: false,
            requestSerialize: (message: Recovery_POPRFRequest) => Buffer.from(message.serialize()),
            requestDeserialize: (bytes: Buffer) => Recovery_POPRFRequest.deserialize(new Uint8Array(bytes)),
            responseSerialize: (message: Recovery_POPRFResponse) => Buffer.from(message.serialize()),
            responseDeserialize: (bytes: Buffer) => Recovery_POPRFResponse.deserialize(new Uint8Array(bytes))
        },
        delete: {
            path: "/org.callisto.recovery.v1.RecoveryService/delete",
            requestStream: false,
            responseStream: false,
            requestSerialize: (message: Delete_Request) => Buffer.from(message.serialize()),
            requestDeserialize: (bytes: Buffer) => Delete_Request.deserialize(new Uint8Array(bytes)),
            responseSerialize: (message: Delete_Response) => Buffer.from(message.serialize()),
            responseDeserialize: (bytes: Buffer) => Delete_Response.deserialize(new Uint8Array(bytes))
        },
        delete_By_Proxy: {
            path: "/org.callisto.recovery.v1.RecoveryService/delete_By_Proxy",
            requestStream: false,
            responseStream: false,
            requestSerialize: (message: Delete_By_Proxy_Request) => Buffer.from(message.serialize()),
            requestDeserialize: (bytes: Buffer) => Delete_By_Proxy_Request.deserialize(new Uint8Array(bytes)),
            responseSerialize: (message: Delete_By_Proxy_Response) => Buffer.from(message.serialize()),
            responseDeserialize: (bytes: Buffer) => Delete_By_Proxy_Response.deserialize(new Uint8Array(bytes))
        },
        update: {
            path: "/org.callisto.recovery.v1.RecoveryService/update",
            requestStream: false,
            responseStream: false,
            requestSerialize: (message: Update_Request) => Buffer.from(message.serialize()),
            requestDeserialize: (bytes: Buffer) => Update_Request.deserialize(new Uint8Array(bytes)),
            responseSerialize: (message: Update_Response) => Buffer.from(message.serialize()),
            responseDeserialize: (bytes: Buffer) => Update_Response.deserialize(new Uint8Array(bytes))
        }
    };
    [method: string]: grpc_1.UntypedHandleCall;
    abstract oprf(call: grpc_1.ServerUnaryCall<OPRFRequest, OPRFResponse>, callback: grpc_1.sendUnaryData<OPRFResponse>): void;
    abstract setup_POPRF(call: grpc_1.ServerUnaryCall<Setup_POPRFRequest, Setup_POPRFResponse>, callback: grpc_1.sendUnaryData<Setup_POPRFResponse>): void;
    abstract setup_Save(call: grpc_1.ServerUnaryCall<Setup_SaveRequest, Setup_SaveResponse>, callback: grpc_1.sendUnaryData<Setup_SaveResponse>): void;
    abstract request_Start(call: grpc_1.ServerUnaryCall<Request_StartRequest, Request_StartResponse>, callback: grpc_1.sendUnaryData<Request_StartResponse>): void;
    abstract recovery_Start(call: grpc_1.ServerUnaryCall<Recovery_StartRequest, Recovery_StartResponse>, callback: grpc_1.sendUnaryData<Recovery_StartResponse>): void;
    abstract recovery_ValidateToken(call: grpc_1.ServerUnaryCall<Recovery_ValidateTokenRequest, Recovery_ValidateTokenResponse>, callback: grpc_1.sendUnaryData<Recovery_ValidateTokenResponse>): void;
    abstract recovery_POPRF(call: grpc_1.ServerUnaryCall<Recovery_POPRFRequest, Recovery_POPRFResponse>, callback: grpc_1.sendUnaryData<Recovery_POPRFResponse>): void;
    abstract delete(call: grpc_1.ServerUnaryCall<Delete_Request, Delete_Response>, callback: grpc_1.sendUnaryData<Delete_Response>): void;
    abstract delete_By_Proxy(call: grpc_1.ServerUnaryCall<Delete_By_Proxy_Request, Delete_By_Proxy_Response>, callback: grpc_1.sendUnaryData<Delete_By_Proxy_Response>): void;
    abstract update(call: grpc_1.ServerUnaryCall<Update_Request, Update_Response>, callback: grpc_1.sendUnaryData<Update_Response>): void;
}
export class RecoveryServiceClient {
    private _address: string;
    private _client: grpc_web_1.GrpcWebClientBase;
    constructor(address: string, credentials?: Object, options?: grpc_web_1.GrpcWebClientBaseOptions) {
        if (!options)
            options = {};
        options.format = options.format || "text";
        this._address = address;
        this._client = new grpc_web_1.GrpcWebClientBase(options);
    }
    private static oprf = new grpc_web_1.MethodDescriptor<OPRFRequest, OPRFResponse>("/org.callisto.recovery.v1.RecoveryService/oprf", grpc_web_1.MethodType.UNARY, OPRFRequest, OPRFResponse, (message: OPRFRequest) => message.serialize(), OPRFResponse.deserialize);
    oprf(message: OPRFRequest, metadata: grpc_web_1.Metadata | null) {
        return this._client.thenableCall<OPRFRequest, OPRFResponse>(this._address + "/org.callisto.recovery.v1.RecoveryService/oprf", message, metadata || {}, RecoveryServiceClient.oprf);
    }
    private static setup_POPRF = new grpc_web_1.MethodDescriptor<Setup_POPRFRequest, Setup_POPRFResponse>("/org.callisto.recovery.v1.RecoveryService/setup_POPRF", grpc_web_1.MethodType.UNARY, Setup_POPRFRequest, Setup_POPRFResponse, (message: Setup_POPRFRequest) => message.serialize(), Setup_POPRFResponse.deserialize);
    setup_POPRF(message: Setup_POPRFRequest, metadata: grpc_web_1.Metadata | null) {
        return this._client.thenableCall<Setup_POPRFRequest, Setup_POPRFResponse>(this._address + "/org.callisto.recovery.v1.RecoveryService/setup_POPRF", message, metadata || {}, RecoveryServiceClient.setup_POPRF);
    }
    private static setup_Save = new grpc_web_1.MethodDescriptor<Setup_SaveRequest, Setup_SaveResponse>("/org.callisto.recovery.v1.RecoveryService/setup_Save", grpc_web_1.MethodType.UNARY, Setup_SaveRequest, Setup_SaveResponse, (message: Setup_SaveRequest) => message.serialize(), Setup_SaveResponse.deserialize);
    setup_Save(message: Setup_SaveRequest, metadata: grpc_web_1.Metadata | null) {
        return this._client.thenableCall<Setup_SaveRequest, Setup_SaveResponse>(this._address + "/org.callisto.recovery.v1.RecoveryService/setup_Save", message, metadata || {}, RecoveryServiceClient.setup_Save);
    }
    private static request_Start = new grpc_web_1.MethodDescriptor<Request_StartRequest, Request_StartResponse>("/org.callisto.recovery.v1.RecoveryService/request_Start", grpc_web_1.MethodType.UNARY, Request_StartRequest, Request_StartResponse, (message: Request_StartRequest) => message.serialize(), Request_StartResponse.deserialize);
    request_Start(message: Request_StartRequest, metadata: grpc_web_1.Metadata | null) {
        return this._client.thenableCall<Request_StartRequest, Request_StartResponse>(this._address + "/org.callisto.recovery.v1.RecoveryService/request_Start", message, metadata || {}, RecoveryServiceClient.request_Start);
    }
    private static recovery_Start = new grpc_web_1.MethodDescriptor<Recovery_StartRequest, Recovery_StartResponse>("/org.callisto.recovery.v1.RecoveryService/recovery_Start", grpc_web_1.MethodType.UNARY, Recovery_StartRequest, Recovery_StartResponse, (message: Recovery_StartRequest) => message.serialize(), Recovery_StartResponse.deserialize);
    recovery_Start(message: Recovery_StartRequest, metadata: grpc_web_1.Metadata | null) {
        return this._client.thenableCall<Recovery_StartRequest, Recovery_StartResponse>(this._address + "/org.callisto.recovery.v1.RecoveryService/recovery_Start", message, metadata || {}, RecoveryServiceClient.recovery_Start);
    }
    private static recovery_ValidateToken = new grpc_web_1.MethodDescriptor<Recovery_ValidateTokenRequest, Recovery_ValidateTokenResponse>("/org.callisto.recovery.v1.RecoveryService/recovery_ValidateToken", grpc_web_1.MethodType.UNARY, Recovery_ValidateTokenRequest, Recovery_ValidateTokenResponse, (message: Recovery_ValidateTokenRequest) => message.serialize(), Recovery_ValidateTokenResponse.deserialize);
    recovery_ValidateToken(message: Recovery_ValidateTokenRequest, metadata: grpc_web_1.Metadata | null) {
        return this._client.thenableCall<Recovery_ValidateTokenRequest, Recovery_ValidateTokenResponse>(this._address + "/org.callisto.recovery.v1.RecoveryService/recovery_ValidateToken", message, metadata || {}, RecoveryServiceClient.recovery_ValidateToken);
    }
    private static recovery_POPRF = new grpc_web_1.MethodDescriptor<Recovery_POPRFRequest, Recovery_POPRFResponse>("/org.callisto.recovery.v1.RecoveryService/recovery_POPRF", grpc_web_1.MethodType.UNARY, Recovery_POPRFRequest, Recovery_POPRFResponse, (message: Recovery_POPRFRequest) => message.serialize(), Recovery_POPRFResponse.deserialize);
    recovery_POPRF(message: Recovery_POPRFRequest, metadata: grpc_web_1.Metadata | null) {
        return this._client.thenableCall<Recovery_POPRFRequest, Recovery_POPRFResponse>(this._address + "/org.callisto.recovery.v1.RecoveryService/recovery_POPRF", message, metadata || {}, RecoveryServiceClient.recovery_POPRF);
    }
    private static delete = new grpc_web_1.MethodDescriptor<Delete_Request, Delete_Response>("/org.callisto.recovery.v1.RecoveryService/delete", grpc_web_1.MethodType.UNARY, Delete_Request, Delete_Response, (message: Delete_Request) => message.serialize(), Delete_Response.deserialize);
    delete(message: Delete_Request, metadata: grpc_web_1.Metadata | null) {
        return this._client.thenableCall<Delete_Request, Delete_Response>(this._address + "/org.callisto.recovery.v1.RecoveryService/delete", message, metadata || {}, RecoveryServiceClient.delete);
    }
    private static delete_By_Proxy = new grpc_web_1.MethodDescriptor<Delete_By_Proxy_Request, Delete_By_Proxy_Response>("/org.callisto.recovery.v1.RecoveryService/delete_By_Proxy", grpc_web_1.MethodType.UNARY, Delete_By_Proxy_Request, Delete_By_Proxy_Response, (message: Delete_By_Proxy_Request) => message.serialize(), Delete_By_Proxy_Response.deserialize);
    delete_By_Proxy(message: Delete_By_Proxy_Request, metadata: grpc_web_1.Metadata | null) {
        return this._client.thenableCall<Delete_By_Proxy_Request, Delete_By_Proxy_Response>(this._address + "/org.callisto.recovery.v1.RecoveryService/delete_By_Proxy", message, metadata || {}, RecoveryServiceClient.delete_By_Proxy);
    }
    private static update = new grpc_web_1.MethodDescriptor<Update_Request, Update_Response>("/org.callisto.recovery.v1.RecoveryService/update", grpc_web_1.MethodType.UNARY, Update_Request, Update_Response, (message: Update_Request) => message.serialize(), Update_Response.deserialize);
    update(message: Update_Request, metadata: grpc_web_1.Metadata | null) {
        return this._client.thenableCall<Update_Request, Update_Response>(this._address + "/org.callisto.recovery.v1.RecoveryService/update", message, metadata || {}, RecoveryServiceClient.update);
    }
}
