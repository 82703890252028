/* eslint-disable react/react-in-jsx-scope */
import type { ComponentChildren } from "preact";
import { h } from "preact";
import { useState } from "preact/hooks";
import "./styles.scss";

const Tooltip = (props: {
	delay?: number;
	children: ComponentChildren;
	direction?: string;
	content: string;
}) => {
	let timeout: NodeJS.Timeout;
	const [active, setActive] = useState<boolean>(false);

	const showTip = () => {
		timeout = setTimeout(() => {
			setActive(true);
		}, props.delay || 400);
	};

	const hideTip = () => {
		clearInterval(timeout);
		setActive(false);
	};

	return (
		<div
			className="Tooltip-Wrapper"
			// When to show the tooltip
			onMouseEnter={showTip}
			onMouseLeave={hideTip}
		>
			{/* Wrapping */}
			{props.children}
			{active && (
				<div className={`Tooltip-Tip ${props.direction || "top"}`}>
					{/* Content */}
					{props.content}
				</div>
			)}
		</div>
	);
};

export default Tooltip;
