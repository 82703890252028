/* eslint-disable react/react-in-jsx-scope */
import { h } from "preact";
import { useState } from "preact/hooks";
import { Loader } from "../../Loader";
import { RecoverClient } from "./types";

import "./EnterBackupCodes.scss";

///
// Step 1 - enter the backup code
//
export const EnterBackupCodes = ({
	client,
	onSuccess,
}: {
	client: RecoverClient;
	onSuccess: (code: string) => void;
}): JSX.Element => {
	const [backupCode, setBackupCode] = useState("");
	const [errorText, setErrorText] = useState("");
	const [showLoader, setShowLoader] = useState(false);

	const checkCode = () => {
		if (backupCode === "") {
			setErrorText("Please enter a backup code.");
			return;
		}

		setShowLoader(true);

		// We're actually logged in if useBackupCode is successful
		setTimeout(() => {
			client
				.useBackupCode(backupCode)
				.then(() => {
					onSuccess(backupCode);
				})
				.catch((error) => {
					setErrorText((error as Error).message);
				})
				.finally(() => {
					setShowLoader(false);
				});
		}, 500);
	};

	return (
		<main id="enterBackupCodes">
			<section className="formWrapper">
				<h1>Enter one of your backup codes</h1>
				<p id="subTitle">
					You will need one of your backup codes to reset your password. These
					were given to you when you created your Callisto Vault account. If you
					do not have these codes, we won’t be able to reset your password at
					this time. Please contact{" "}
					<a href="mailto:support@projectcallisto.org">
						support@projectcallisto.org
					</a>
					.
				</p>
				<div className="queryWrapper">
					<input
						id="schoolEmail"
						type="text"
						name="userEmail"
						placeholder="Enter one of your backup codes*"
						autoComplete="false"
						value={backupCode}
						onChange={(evt) => setBackupCode(evt.currentTarget.value)}
					/>
				</div>
				<button
					className="btn primary"
					type="button"
					name="button"
					onClick={checkCode}
				>
					Reset Password
				</button>
				<p className="pwerror" role="alert">
					{errorText}
				</p>
				<Loader show={showLoader} />
			</section>
		</main>
	);
};
