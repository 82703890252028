import sodium from "libsodium-wrappers-sumo";

const potentials =
	"abcdefghjklmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ23456789!@#$%&*";

export const makeCode = (length: number = 16): string => {
	let output = "";
	for (let i = 0; i < length; i++) {
		const pick = sodium.randombytes_uniform(potentials.length);
		output += potentials[pick];
	}

	return output;
};
