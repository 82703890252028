/* eslint-disable react/react-in-jsx-scope */
import { ComponentChildren, Fragment, h } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";

interface TimerProps {
	children: ComponentChildren;
	timeoutInMinutes: number;
	onTimeout(): void;
}

export const ActionTimer = ({
	children,
	onTimeout,
	timeoutInMinutes,
}: TimerProps): JSX.Element => {
	const [lastActiveTime, setLastActiveTime] = useState<Date>(new Date());
	const [timeoutTime, setTimeoutTime] = useState<Date>(
		new Date(lastActiveTime.getTime() + 1000 * 60 * timeoutInMinutes),
	);
	const [idleTime, setIdleTime] = useState<number>(0);
	const idleTimer = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		const events = [
			"load",
			"mousemove",
			"mousedown",
			"click",
			"scroll",
			"keypress",
		];

		for (const evnt of events) {
			window.addEventListener(evnt, handleCancel);
		}

		return () => {
			for (const evnt of events) {
				window.removeEventListener(evnt, handleCancel);
			}
		};
	});

	useEffect(() => {
		const now = Date.now();
		if (now >= timeoutTime.getTime()) {
			onTimeout();
			setTimeoutTime(() => new Date(now + 1000 * 60 * timeoutInMinutes));
		}
		idleTimer.current = setTimeout(() => setIdleTime((prev) => prev + 1), 1);
		return () => clearTimer(idleTimer.current);
	}, [idleTime]);

	useEffect(() => {
		setTimeoutTime(
			() => new Date(lastActiveTime.getTime() + 1000 * 60 * timeoutInMinutes),
		);
	}, [lastActiveTime]);

	const clearTimer = (timer: NodeJS.Timeout | null) => {
		if (timer !== null) {
			clearInterval(timer);
		}
	};

	const handleCancel = () => {
		setLastActiveTime(new Date());
		setIdleTime(0);
	};

	return (
		<Fragment>
			<div>{children}</div>
		</Fragment>
	);
};
