import sodium from "libsodium-wrappers-sumo";
import { Decoder, Encoder } from "io-ts";
import { isRight } from "fp-ts/Either";

/**
 * Box is a wrapper around libsodium's crypto_box primitive, that gives some
 * convenience functions around multiple key wrappers for ciphertexts.
 */
export module Box {
	/**
	 * Generate a random keypair for a new user.
	 *
	 * @returns Random keys suitable for encrypting data.
	 */
	export const keygen = (): sodium.KeyPair => sodium.crypto_box_keypair();

	/**
	 * Used to add a second layer on encryption on top of already-encrypted data.
	 *
	 * @param pk   New key to layer on top of data.
	 * @param data Ciphertext to encrypt with.
	 *
	 * @returns    Layered ciphertext with the new key added on.
	 */
	export const rawEncrypt = (
		pk: Uint8Array,
		data: string | Uint8Array,
	): Uint8Array => {
		const dValue =
			typeof data !== "string" && data instanceof Buffer
				? Uint8Array.from(data)
				: data;
		const kValue =
			typeof data !== "string" && data instanceof Buffer
				? Uint8Array.from(pk)
				: pk;

		return sodium.crypto_box_seal(dValue, kValue);
	};

	/**
	 * Encrypt data to a given key in a typesafe manner
	 *
	 * @param pk The key to use to do the encryption
	 * @param data The object whose data is to be encrypted
	 * @param type io-ts type of the data to be encrypted
	 */
	export const tsEncrypt = <T extends Encoder<D, string | Uint8Array>, D>(
		pk: Uint8Array,
		data: D,
		type: T,
	): Uint8Array => rawEncrypt(pk, type.encode(data));

	/**
	 * Used to strip off a layer of encryption from a ciphertext.
	 *
	 * @param ciphertext Encrypted data to strip a key from.
	 * @param pk         Public key of first layer.
	 * @param sk         Secret key of first layer.
	 *
	 * @returns          Possibly still-encrypted data, with this layer stripped.
	 */
	export const rawDecrypt = (
		ciphertext: Uint8Array,
		pk: Uint8Array,
		sk: Uint8Array,
	): Buffer => {
		// XXX: This is here to support multiple decryption steps in tests.
		if (ciphertext instanceof Buffer) {
			ciphertext = new Uint8Array(ciphertext);
		}
		return Buffer.from(sodium.crypto_box_seal_open(ciphertext, pk, sk));
	};

	export const tsDecrypt = <T extends Decoder<string | Uint8Array, D>, D>(
		ciphertext: Uint8Array,
		pk: Uint8Array,
		sk: Uint8Array,
		type: T,
	): D => {
		const buf = rawDecrypt(ciphertext, pk, sk);
		const decoded = type.decode(buf.toString("utf-8"));
		if (isRight(decoded)) {
			return decoded.right;
		}
	};
}
