/* eslint-disable react/react-in-jsx-scope */
import { h } from "preact";
import { useState } from "preact/hooks";

import "./styles.scss";

const showIcon = require("../../assets/icons/view.svg") as string;
const hideIcon = require("../../assets/icons/hide.svg") as string;

export const PasswordInput = ({
	value,
	onChange,
	placeholder,
	className,
}: {
	value: string;
	onChange: (value: string) => void;
	className?: string;
	placeholder?: string;
}): JSX.Element => {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<div className="pswdWrapper">
			<input
				className={className ?? "pswd"}
				type={showPassword ? "text" : "password"}
				value={value}
				onChange={(evt) => onChange(evt.currentTarget.value)}
				name="password"
				placeholder={placeholder ?? "Create password 8+ characters*"}
				autoComplete="false"
			/>
			<img
				src={showPassword ? hideIcon : showIcon}
				alt="view indicator"
				onClick={() => setShowPassword(!showPassword)}
			/>
		</div>
	);
};
