/* eslint-disable react/react-in-jsx-scope */
import { Fragment, h } from "preact";
import { useContext, useEffect, useRef, useState } from "preact/hooks";
import { route } from "preact-router";

import { CaseStatus } from "../../../../../../lib/components/case-status";
import { ReassignModal } from "../../components/reassign-modal";
import { EditModal } from "../../components/status-modal";
import {
	accountRecoveryIsSetUp,
	CaseStatus as DataCaseStatus,
	CompareFunction,
	formatDate,
	formatStatus,
	LegalFilters,
	locEntry,
} from "../../../../../../lib/data";
import { useCases } from "../../legalData";
import { LegalClientContext } from "../../components/LegalClientContext";
import { Toast } from "../../../../../../lib/components/toast";
import { SortableHeader } from "../../../../../../lib/components/SortableHeader";
import { StringArrayFilter } from "../../../../../../lib/components/Filter/StringArrayFilter";
import { StringFilter } from "../../../../../../lib/components/Filter/StringFilter";

import "./styles.scss";
import { useCloseCase } from "../../components/hooks/useCloseCase";
import Tooltip from "../../../../../../lib/components/tooltip/Tooltip";

interface SortState {
	sortAsc: {
		id: boolean;
		survivor: boolean;
		status: boolean;
		matchDate: boolean;
		perpetrator: boolean;
		state: boolean;
		campus: boolean;
		dateAssigned: boolean;
	};
	header: string;
	comparer: CompareFunction;
}

const defaultFilters: LegalFilters = {
	caseTypes: "all",
	statuses: [],
	survivors: [],
	matchDates: [],
	perpetrators: [],
	incidentStates: [],
	campuses: [],
	assignedDates: [],
	custom: {
		statuses: undefined,
		survivors: undefined,
		matchDates: undefined,
		perpetrators: undefined,
		incidentStates: undefined,
		campuses: undefined,
		assignedDates: undefined,
	},
};

const tableHeaders: Record<string, string> = {
	id: "CASE ID",
	survivor: "SURVIVOR NAME",
	status: "CASE STATUS",
	matchDate: "MATCH DATE",
	perpetrator: "PERPETRATOR",
	state: "STATE OF INCIDENT",
	campus: "COLLEGE",
	assignedDate: "DATE ASSIGNED",
};

const stringCompare = (a: string, b: string) => {
	if ((!a && b) || a < b) {
		return -1;
	}

	if ((a && !b) || a > b) {
		return 1;
	}

	return 0;
};

const dateCompare = (a: Date, b: Date) => {
	if (a && !b) {
		return -1;
	}

	if (!a && b) {
		return 1;
	}

	if (!a && !b) {
		return 0;
	}

	return a.getTime() - b.getTime();
};

// eslint-disable-next-line no-empty-pattern
export const DashboardPage = ({}: { path?: string }): JSX.Element => {
	const [banner, setBanner] = useState("");
	const { cases, casesLoaded, sort, refreshCases } = useCases();
	const [showEditModal, setShowEdit] = useState<locEntry | null>(null);
	const [showAssignModal, setShowAssign] = useState<locEntry | null>(null);
	const [onlyCaseForSurvivor, setOnlyCaseForSurvivor] =
		useState<boolean>(false);
	const legalClient = useContext(LegalClientContext);
	const [showGenerateKeyButton, setShowGenerateKeyButton] =
		useState<boolean>(false);
	const [inErrorState, setInErrorState] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>("");
	const [rerender, setRerender] = useState<number>(0);
	const [sortState, setSortState] = useState<SortState>({
		sortAsc: {
			id: false,
			survivor: false,
			status: false,
			matchDate: false,
			perpetrator: false,
			state: false,
			campus: false,
			dateAssigned: false,
		},
		header: "",
		comparer: null,
	});
	const [showFilters, setShowFilters] = useState<boolean>(false);
	const [displayCases, setDisplayCases] = useState<locEntry[]>([...cases]);
	const [filters, setFilters] = useState<LegalFilters>(defaultFilters);
	const [closedStatuses] = useState<string[]>([]);
	const [nonClosedStatuses] = useState<string[]>([]);
	const [statuses, setStatuses] = useState<string[]>([]);
	const [survivors, setSurvivors] = useState<string[]>([]);
	const [matchDates, setMatchDates] = useState<string[]>([]);
	const [perpetrators, setPerpetrators] = useState<string[]>([]);
	const [incidentStates, setIncidentStates] = useState<string[]>([]);
	const [campuses, setCampuses] = useState<string[]>([]);
	const [assignedDates, setAssignedDates] = useState<string[]>([]);
	const { setCaseData } = useCloseCase();
	const formRef = useRef<HTMLFormElement>(null);

	const [envVars, setEnvVars] = useState<Record<string, string>>({});
	const [shareRecalculationComplete, setShareRecalculationComplete] =
		useState<boolean>(null);
	const [shareRecalculationError, setShareRecalculationError] =
		useState<boolean>(null);
	const [idCopied, setIdCopied] = useState<boolean>(false);

	useEffect(() => {
		const getEnvVars = async () => {
			setEnvVars(await legalClient.getEnvironmentVariables());
		};

		void getEnvVars();
	}, []);

	useEffect(() => {
		setShowGenerateKeyButton(legalClient.isLocStar);

		for (const status in DataCaseStatus) {
			if (status.includes("CLOSED")) {
				closedStatuses.push(status);
			} else {
				nonClosedStatuses.push(status);
			}
		}
	}, []);

	useEffect(() => {
		if (!accountRecoveryIsSetUp(legalClient)) {
			route("/setup-recovery");
		}
	}, [envVars]);

	useEffect(() => {
		const tempStatuses: Set<string> = new Set<string>();
		const tempSurvivors: Set<string> = new Set<string>();
		const tempMatchDates: Set<string> = new Set<string>();
		const tempPerpetrators: Set<string> = new Set<string>();
		const tempIncidentStates: Set<string> = new Set<string>();
		const tempCampuses: Set<string> = new Set<string>();
		const tempAssignedDates: Set<string> = new Set<string>();

		for (const entry of cases) {
			tempStatuses.add(entry.status);
			tempSurvivors.add(entry.userContactInfo.name);
			tempMatchDates.add(formatDate(entry.matchFound));
			tempPerpetrators.add(entry.perpID);
			tempIncidentStates.add(entry.entry.incidentState);
			tempCampuses.add(entry.userSignupCampus);
			tempAssignedDates.add(formatDate(entry.assigned));
		}
		setStatuses([...tempStatuses].sort());
		setSurvivors([...tempSurvivors].sort());
		setMatchDates([...tempMatchDates].sort().reverse());
		setPerpetrators([...tempPerpetrators].sort());
		setIncidentStates([...tempIncidentStates].sort());
		setCampuses([...tempCampuses].sort());
		setAssignedDates([...tempAssignedDates].sort().reverse());

		setDisplayCases([...cases]);

		if (sortState.comparer) {
			sort(sortState.comparer);
		}
	}, [casesLoaded, rerender]);

	useEffect(() => {
		let casesToDisplay = [...cases];
		if (filters.caseTypes === "closed") {
			casesToDisplay = casesToDisplay.filter((entry) =>
				closedStatuses.includes(entry.status),
			);
		} else if (filters.caseTypes === "open") {
			casesToDisplay = casesToDisplay.filter((entry) =>
				nonClosedStatuses.includes(entry.status),
			);
		}

		if (filters.custom.statuses) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				formatStatus(entry.status).includes(filters.custom.statuses),
			);
		}
		if (filters.statuses.length > 0) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				filters.statuses.includes(entry.status),
			);
		}

		if (filters.custom.survivors) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				entry.userContactInfo.name.includes(filters.custom.survivors),
			);
		}
		if (filters.survivors.length > 0) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				filters.survivors.includes(entry.userContactInfo.name),
			);
		}

		if (filters.custom.matchDates) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				formatDate(entry.matchFound).includes(
					filters.custom.matchDates.replace(/-/g, "\u2011"),
				),
			);
		}
		if (filters.matchDates.length > 0) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				filters.matchDates.includes(formatDate(entry.matchFound)),
			);
		}

		if (filters.custom.perpetrators) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				entry.perpID.includes(filters.custom.perpetrators),
			);
		}
		if (filters.perpetrators.length > 0) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				filters.perpetrators.includes(entry.perpID),
			);
		}

		if (filters.custom.incidentStates) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				entry.entry.incidentState.includes(filters.custom.incidentStates),
			);
		}
		if (filters.incidentStates.length > 0) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				filters.incidentStates.includes(entry.entry.incidentState),
			);
		}

		if (filters.custom.campuses) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				entry.userSignupCampus.includes(filters.custom.campuses),
			);
		}
		if (filters.campuses.length > 0) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				filters.campuses.includes(entry.userSignupCampus),
			);
		}

		if (filters.custom.assignedDates) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				formatDate(entry.assigned).includes(
					filters.custom.assignedDates.replace(/-/g, "\u2011"),
				),
			);
		}
		if (filters.assignedDates.length > 0) {
			casesToDisplay = casesToDisplay.filter((entry) =>
				filters.assignedDates.includes(formatDate(entry.assigned)),
			);
		}

		setDisplayCases(casesToDisplay);
	}, [filters, rerender, casesLoaded]);

	const forceRerender = () => {
		setRerender(rerender + 1);
	};

	const setToast = (val: string) => {
		setBanner(val);
		setTimeout(() => {
			setBanner("");
		}, 5000);
	};

	useEffect(() => {
		if (showAssignModal) {
			setOnlyCaseForSurvivor(
				cases.filter(
					(entry) => entry.entry.userID === showAssignModal.entry.userID,
				).length === 1,
			);
		}
	}, [showAssignModal]);

	const handleGenerateNewKey = async () => {
		const loader = document.getElementById("loaderWrapper");
		loader.style.display = "flex";
		try {
			await legalClient.generateSharedKey();
			setToast("Key generated successfully");
			setShowGenerateKeyButton(false);
		} catch (error) {
			setErrorMsg((error as Error).message);
			setInErrorState(true);
		} finally {
			loader.style.display = "none";
		}
	};

	const setUpCompareFunction =
		(
			ascending: boolean,
			comparer:
				| ((a: string, b: string) => number)
				| ((a: Date, b: Date) => number),
			locEntryProperty: string,
		) =>
		(a: locEntry, b: locEntry) =>
			ascending
				? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					comparer(a[locEntryProperty], b[locEntryProperty])
				: -1 * comparer(a[locEntryProperty], b[locEntryProperty]);

	const setCustomStringFilter = (filter: string, value: string) => {
		if (value) {
			setFilters({
				...filters,
				custom: {
					...filters.custom,
					[filter]: value,
				},
			});
		} else {
			setFilters({
				...filters,
				custom: {
					...filters.custom,
					[filter]: undefined,
				},
			});
		}
	};

	const setStringArrayFilter = (filter: string, value: string[]) => {
		if (!value || value.length === 0) {
			setFilters({
				...filters,
				[filter]: [],
			});
		} else {
			setFilters({
				...filters,
				[filter]: value,
			});
		}
	};

	const setStringFilter = (filter: string, value: string) => {
		setFilters({
			...filters,
			[filter]: value,
		});
	};

	const recalculateShares = async () => {
		const loaderWrapper = document.getElementById("loaderWrapper");
		loaderWrapper.style.display = "flex";
		try {
			const success = await legalClient.recalculateShares();
			if (success) {
				setShareRecalculationComplete(true);
				setShareRecalculationError(false);
			} else {
				setShareRecalculationComplete(false);
				setShareRecalculationError(true);
			}
		} finally {
			loaderWrapper.style.display = "none";
		}
	};

	return (
		<Fragment>
			<main id="dashboard">
				<div id="dashCard">
					{banner && <div className="toast">{banner}</div>}
					<div id="generateKeyButton">
						{showGenerateKeyButton && (
							<button
								type="button"
								className="primaryButton"
								onClick={async () => {
									await handleGenerateNewKey();
								}}
							>
								Generate new key
							</button>
						)}
					</div>
					{envVars.UI_ENV_REWRITE_NEEDED &&
						legalClient.isAbleToRewriteShares && (
							<button
								className="secondaryButton"
								onClick={() => recalculateShares()}
							>
								Recalculate shares
							</button>
						)}
					<button
						className="iconButton rightAligned"
						onClick={() => setShowFilters(!showFilters)}
					>
						<img
							src={
								require("../../../../../../lib/assets/icons/sliders.svg") as string
							}
							width="15"
							alt="toggle view filters"
						/>{" "}
						{showFilters ? "Hide" : "Show"} Filters
					</button>

					{showFilters && (
						<div id="filters">
							<button
								className="iconButton"
								onClick={() => {
									setFilters(defaultFilters);
									formRef.current.reset();
								}}
							>
								<img
									src={
										require("../../../../../../lib/assets/icons/ban.svg") as string
									}
									width="12"
									alt="clear all filters"
								/>{" "}
								Clear all filters
							</button>

							<br />

							<form id="filters" ref={formRef}>
								<StringFilter
									filter="caseTypes"
									label="Case types"
									filters={filters}
									onChange={setStringFilter}
									values={[
										{
											value: "both",
											displayValue: "Show both open and closed cases",
										},
										{ value: "closed", displayValue: "Show only closed cases" },
										{ value: "open", displayValue: "Show only open cases" },
									]}
								/>

								<br />

								<StringArrayFilter
									filter="survivors"
									label="Survivor name"
									filters={filters}
									onChange={setStringArrayFilter}
									onUpdateCustom={setCustomStringFilter}
									values={survivors.map((s) => ({ value: s }))}
								/>

								<StringArrayFilter
									filter="statuses"
									label="Status"
									filters={filters}
									onChange={setStringArrayFilter}
									onUpdateCustom={setCustomStringFilter}
									values={statuses.map((s) => ({
										value: s,
										displayValue: formatStatus(s),
									}))}
								/>

								<StringArrayFilter
									filter="matchDates"
									label="Match date"
									filters={filters}
									onChange={setStringArrayFilter}
									onUpdateCustom={setCustomStringFilter}
									values={matchDates.map((md) => ({ value: md }))}
								/>

								<StringArrayFilter
									filter="perpetrators"
									label="Perpetrator ID"
									filters={filters}
									onChange={setStringArrayFilter}
									onUpdateCustom={setCustomStringFilter}
									values={perpetrators.map((perpId) => ({ value: perpId }))}
								/>

								<StringArrayFilter
									filter="incidentStates"
									label="State"
									filters={filters}
									onChange={setStringArrayFilter}
									onUpdateCustom={setCustomStringFilter}
									values={incidentStates.map((state) => ({ value: state }))}
								/>

								<StringArrayFilter
									filter="campuses"
									label="College"
									filters={filters}
									onChange={setStringArrayFilter}
									onUpdateCustom={setCustomStringFilter}
									values={campuses.map((p) => ({ value: p }))}
								/>

								<StringArrayFilter
									filter="assignedDates"
									label="Assigned date"
									filters={filters}
									onChange={setStringArrayFilter}
									onUpdateCustom={setCustomStringFilter}
									values={assignedDates.map((ad) => ({ value: ad }))}
								/>
							</form>
						</div>
					)}
					<div id="dashboardTable">
						<table>
							<thead id="dashHeader">
								<SortableHeader
									compare={(a, b) => stringCompare(a.id, b.id)}
									doSort={sortState.header === tableHeaders.id}
									onClick={() => {
										setSortState({
											sortAsc: {
												...sortState.sortAsc,
												id: !sortState.sortAsc.id,
											},
											header: tableHeaders.id,
											comparer: setUpCompareFunction(
												!sortState.sortAsc.id,
												stringCompare,
												"id",
											),
										});
									}}
									onSort={forceRerender}
									sort={sort}
									sortAsc={sortState.sortAsc.id}
								>
									{tableHeaders.id}
								</SortableHeader>
								<SortableHeader
									compare={(a, b) =>
										stringCompare(
											a.userContactInfo.name,
											b.userContactInfo.name,
										)
									}
									doSort={sortState.header === tableHeaders.survivor}
									onClick={() => {
										setSortState({
											sortAsc: {
												...sortState.sortAsc,
												survivor: !sortState.sortAsc.survivor,
											},
											header: tableHeaders.survivor,
											comparer: (a: locEntry, b: locEntry) =>
												!sortState.sortAsc.survivor
													? stringCompare(
															a.userContactInfo.name,
															b.userContactInfo.name,
														)
													: -1 *
														stringCompare(
															a.userContactInfo.name,
															b.userContactInfo.name,
														),
										});
									}}
									onSort={forceRerender}
									sort={sort}
									sortAsc={sortState.sortAsc.survivor}
								>
									{tableHeaders.survivor}
								</SortableHeader>
								<SortableHeader
									doSort={sortState.header === tableHeaders.status}
									compare={(a, b) => stringCompare(a.status, b.status)}
									onSort={forceRerender}
									onClick={() => {
										setSortState({
											sortAsc: {
												...sortState.sortAsc,
												status: !sortState.sortAsc.status,
											},
											header: tableHeaders.status,
											comparer: setUpCompareFunction(
												!sortState.sortAsc.status,
												stringCompare,
												"status",
											),
										});
									}}
									sort={sort}
									sortAsc={sortState.sortAsc.status}
								>
									{tableHeaders.status}
								</SortableHeader>
								<SortableHeader
									doSort={sortState.header === tableHeaders.matchDate}
									compare={(a, b) => dateCompare(a.matchFound, b.matchFound)}
									onSort={forceRerender}
									onClick={() => {
										setSortState({
											sortAsc: {
												...sortState.sortAsc,
												matchDate: !sortState.sortAsc.matchDate,
											},
											header: tableHeaders.matchDate,
											comparer: setUpCompareFunction(
												!sortState.sortAsc.matchDate,
												dateCompare,
												"matchFound",
											),
										});
									}}
									sort={sort}
									sortAsc={sortState.sortAsc.matchDate}
								>
									{tableHeaders.matchDate}
								</SortableHeader>
								<SortableHeader
									doSort={sortState.header === tableHeaders.perpetrator}
									compare={(a, b) => stringCompare(a.perpID, b.perpID)}
									onSort={forceRerender}
									onClick={() => {
										setSortState({
											sortAsc: {
												...sortState.sortAsc,
												perpetrator: !sortState.sortAsc.perpetrator,
											},
											header: tableHeaders.perpetrator,
											comparer: setUpCompareFunction(
												!sortState.sortAsc.perpetrator,
												stringCompare,
												"perpID",
											),
										});
									}}
									sort={sort}
									sortAsc={sortState.sortAsc.perpetrator}
								>
									{tableHeaders.perpetrator}
								</SortableHeader>
								<SortableHeader
									doSort={sortState.header === tableHeaders.state}
									compare={(a, b) =>
										stringCompare(a.entry.incidentState, b.entry.incidentState)
									}
									onSort={forceRerender}
									onClick={() => {
										setSortState({
											sortAsc: {
												...sortState.sortAsc,
												state: !sortState.sortAsc.state,
											},
											header: tableHeaders.state,
											comparer: (a: locEntry, b: locEntry) =>
												!sortState.sortAsc.state
													? stringCompare(
															a.entry.incidentState,
															b.entry.incidentState,
														)
													: -1 *
														stringCompare(
															a.entry.incidentState,
															b.entry.incidentState,
														),
										});
									}}
									sort={sort}
									sortAsc={sortState.sortAsc.state}
								>
									{tableHeaders.state}
								</SortableHeader>
								<SortableHeader
									doSort={sortState.header === tableHeaders.campus}
									compare={(a, b) =>
										stringCompare(a.userSignupCampus, b.userSignupCampus)
									}
									onSort={forceRerender}
									onClick={() => {
										setSortState({
											sortAsc: {
												...sortState.sortAsc,
												campus: !sortState.sortAsc.campus,
											},
											header: tableHeaders.campus,
											comparer: setUpCompareFunction(
												!sortState.sortAsc.campus,
												stringCompare,
												"userSignupCampus",
											),
										});
									}}
									sort={sort}
									sortAsc={sortState.sortAsc.campus}
								>
									{tableHeaders.campus}
								</SortableHeader>
								<SortableHeader
									doSort={sortState.header === tableHeaders.assignedDate}
									compare={(a, b) => dateCompare(a.assigned, b.assigned)}
									onSort={forceRerender}
									onClick={() => {
										setSortState({
											sortAsc: {
												...sortState.sortAsc,
												dateAssigned: !sortState.sortAsc.dateAssigned,
											},
											header: tableHeaders.assignedDate,
											comparer: setUpCompareFunction(
												!sortState.sortAsc.dateAssigned,
												dateCompare,
												"assigned",
											),
										});
									}}
									sort={sort}
									sortAsc={sortState.sortAsc.dateAssigned}
								>
									{tableHeaders.assignedDate}
								</SortableHeader>
								<th />
							</thead>
							{!casesLoaded && (
								<tr>
									<td colSpan={8}>
										<div className="smallLoader centered" />
									</td>
								</tr>
							)}
							{casesLoaded &&
								displayCases.map((entry) => (
									<tr className="record" key={entry.id}>
										<td className="id">
											<Tooltip content={entry.id} direction="right">
												{entry.id.substring(0, 8)}
											</Tooltip>
											<button
												className="iconButton copyButton"
												onClick={async () => {
													await navigator.clipboard.writeText(entry.id);
													setIdCopied(true);
												}}
											>
												<img
													src={
														require("../../../../../../lib/assets/icons/copy-sharp-light.svg") as string
													}
													width="15"
													alt="copy id to clipboard"
												/>
											</button>
										</td>
										<td>{entry.userContactInfo.name}</td>
										<td>
											<CaseStatus
												status={entry.status}
												lastUpdated={entry.statusChanged}
											/>
										</td>
										<td>{formatDate(entry.matchFound)}</td>
										<td className="id">
											<Tooltip content={entry.perpID}>
												{entry.perpID.substring(0, 8)}
											</Tooltip>
											<button
												className="iconButton perpCopyButton"
												onClick={async () => {
													await navigator.clipboard.writeText(entry.perpID);
													setIdCopied(true);
												}}
											>
												<img
													src={
														require("../../../../../../lib/assets/icons/copy-sharp-light.svg") as string
													}
													width="15"
													alt="copy id to clipboard"
												/>
											</button>
										</td>
										<td>{entry.entry.incidentState}</td>
										<td>{entry.userCampusAtTimeOfEntryCreation}</td>
										<td>{entry.assigned && formatDate(entry.assigned)}</td>
										<td className="moreIcon">
											<img
												src={require("./more.svg") as string}
												alt="more icons"
											/>
											<div className="moreDropdown">
												<button
													onClick={() => {
														route(`case/${entry.id}`);
													}}
												>
													View Case
												</button>
												<button onClick={() => setShowEdit(entry)}>
													Edit Status
												</button>
												<button onClick={() => setShowAssign(entry)}>
													Reassign Case
												</button>
												{entry.status.includes("CLOSED") &&
													!entry.closeCaseSurveyCompleted && (
														<button
															onClick={() => {
																setCaseData(
																	entry.id,
																	entry.status,
																	entry.statusChanged,
																);
																route("/close-case");
															}}
														>
															Complete survey
														</button>
													)}
											</div>
										</td>
									</tr>
								))}
						</table>
					</div>
				</div>

				<div id="loaderWrapper">
					<div className="loaderContainer">
						<div className="loader" />
					</div>
				</div>
			</main>

			{showAssignModal && (
				<ReassignModal
					closeFunc={() => {
						setShowAssign(null);
						refreshCases();
					}}
					entry={showAssignModal}
					onlyCaseForSurvivor={onlyCaseForSurvivor}
				/>
			)}
			{showEditModal && (
				<EditModal
					onClose={() => {
						setShowEdit(null);
						refreshCases();
					}}
					entry={showEditModal}
				/>
			)}
			{shareRecalculationComplete && (
				<Toast
					error={false}
					message={"Share recalculation complete"}
					closeFunc={async () => {
						setShareRecalculationComplete(false);
						return Promise.resolve();
					}}
				/>
			)}
			{shareRecalculationError && (
				<Toast
					error={true}
					message={"Further recalculation required"}
					closeFunc={async () => {
						setShareRecalculationError(false);
						return Promise.resolve();
					}}
					timeout={0}
				/>
			)}

			{inErrorState && (
				<Toast
					error={true}
					message={errorMsg}
					closeFunc={async () => Promise.resolve()}
				/>
			)}
			{idCopied && (
				<Toast
					error={false}
					message="ID copied to clipboard"
					closeFunc={async () => {
						setIdCopied(false);
						return Promise.resolve();
					}}
				/>
			)}
		</Fragment>
	);
};
