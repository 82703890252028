/**
 * Generated by the protoc-gen-ts.  DO NOT EDIT!
 * compiler version: 5.28.3
 * source: config.proto
 * git: https://github.com/thesayyn/protoc-gen-ts */
import * as pb_1 from "google-protobuf";
import * as grpc_1 from "@grpc/grpc-js";
import * as grpc_web_1 from "grpc-web";
export class GetCurrentFlagsRequest extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {}) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") { }
    }
    static fromObject(data: {}): GetCurrentFlagsRequest {
        const message = new GetCurrentFlagsRequest({});
        return message;
    }
    toObject() {
        const data: {} = {};
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetCurrentFlagsRequest {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetCurrentFlagsRequest();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): GetCurrentFlagsRequest {
        return GetCurrentFlagsRequest.deserialize(bytes);
    }
}
export class GetCurrentFlagsResponse extends pb_1.Message {
    #one_of_decls: number[][] = [];
    constructor(data?: any[] | {
        flags?: Map<string, string>;
    }) {
        super();
        pb_1.Message.initialize(this, Array.isArray(data) ? data : [], 0, -1, [], this.#one_of_decls);
        if (!Array.isArray(data) && typeof data == "object") {
            if ("flags" in data && data.flags != undefined) {
                this.flags = data.flags;
            }
        }
        if (!this.flags)
            this.flags = new Map();
    }
    get flags() {
        return pb_1.Message.getField(this, 1) as any as Map<string, string>;
    }
    set flags(value: Map<string, string>) {
        pb_1.Message.setField(this, 1, value as any);
    }
    static fromObject(data: {
        flags?: {
            [key: string]: string;
        };
    }): GetCurrentFlagsResponse {
        const message = new GetCurrentFlagsResponse({});
        if (typeof data.flags == "object") {
            message.flags = new Map(Object.entries(data.flags));
        }
        return message;
    }
    toObject() {
        const data: {
            flags?: {
                [key: string]: string;
            };
        } = {};
        if (this.flags != null) {
            data.flags = (Object.fromEntries)(this.flags);
        }
        return data;
    }
    serialize(): Uint8Array;
    serialize(w: pb_1.BinaryWriter): void;
    serialize(w?: pb_1.BinaryWriter): Uint8Array | void {
        const writer = w || new pb_1.BinaryWriter();
        for (const [key, value] of this.flags) {
            writer.writeMessage(1, this.flags, () => {
                writer.writeString(1, key);
                writer.writeString(2, value);
            });
        }
        if (!w)
            return writer.getResultBuffer();
    }
    static deserialize(bytes: Uint8Array | pb_1.BinaryReader): GetCurrentFlagsResponse {
        const reader = bytes instanceof pb_1.BinaryReader ? bytes : new pb_1.BinaryReader(bytes), message = new GetCurrentFlagsResponse();
        while (reader.nextField()) {
            if (reader.isEndGroup())
                break;
            switch (reader.getFieldNumber()) {
                case 1:
                    reader.readMessage(message, () => pb_1.Map.deserializeBinary(message.flags as any, reader, reader.readString, reader.readString));
                    break;
                default: reader.skipField();
            }
        }
        return message;
    }
    serializeBinary(): Uint8Array {
        return this.serialize();
    }
    static deserializeBinary(bytes: Uint8Array): GetCurrentFlagsResponse {
        return GetCurrentFlagsResponse.deserialize(bytes);
    }
}
export abstract class UnimplementedConfigServiceService {
    static definition = {
        get_current_flags: {
            path: "/org.callistovault.core.ConfigService/get_current_flags",
            requestStream: false,
            responseStream: false,
            requestSerialize: (message: GetCurrentFlagsRequest) => Buffer.from(message.serialize()),
            requestDeserialize: (bytes: Buffer) => GetCurrentFlagsRequest.deserialize(new Uint8Array(bytes)),
            responseSerialize: (message: GetCurrentFlagsResponse) => Buffer.from(message.serialize()),
            responseDeserialize: (bytes: Buffer) => GetCurrentFlagsResponse.deserialize(new Uint8Array(bytes))
        }
    };
    [method: string]: grpc_1.UntypedHandleCall;
    abstract get_current_flags(call: grpc_1.ServerUnaryCall<GetCurrentFlagsRequest, GetCurrentFlagsResponse>, callback: grpc_1.sendUnaryData<GetCurrentFlagsResponse>): void;
}
export class ConfigServiceClient {
    private _address: string;
    private _client: grpc_web_1.GrpcWebClientBase;
    constructor(address: string, credentials?: Object, options?: grpc_web_1.GrpcWebClientBaseOptions) {
        if (!options)
            options = {};
        options.format = options.format || "text";
        this._address = address;
        this._client = new grpc_web_1.GrpcWebClientBase(options);
    }
    private static get_current_flags = new grpc_web_1.MethodDescriptor<GetCurrentFlagsRequest, GetCurrentFlagsResponse>("/org.callistovault.core.ConfigService/get_current_flags", grpc_web_1.MethodType.UNARY, GetCurrentFlagsRequest, GetCurrentFlagsResponse, (message: GetCurrentFlagsRequest) => message.serialize(), GetCurrentFlagsResponse.deserialize);
    get_current_flags(message: GetCurrentFlagsRequest, metadata: grpc_web_1.Metadata | null) {
        return this._client.thenableCall<GetCurrentFlagsRequest, GetCurrentFlagsResponse>(this._address + "/org.callistovault.core.ConfigService/get_current_flags", message, metadata || {}, ConfigServiceClient.get_current_flags);
    }
}
