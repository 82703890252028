/* eslint-disable react/react-in-jsx-scope */
import { h } from "preact";

import "./styles.scss";

export const Loader = ({ show }: { show?: boolean }): JSX.Element => (
	<div style={{ display: show ? "flex" : "none" }} id="loaderWrapper">
		<div className="loaderContainer">
			<div className="loader" />
		</div>
	</div>
);
