/* eslint-disable react/react-in-jsx-scope */
import { Fragment, h } from "preact";
import { route } from "preact-router";
import { useEffect, useState } from "preact/hooks";
import { adminUserData, dlocUserData, locUserData } from "../data";
import { Toast } from "./toast";

interface AccountRecoveryClient {
	token: string;
	userData: dlocUserData | locUserData | adminUserData;
	verifyAccountRecoveryToken: (token: string) => Promise<{
		success: boolean;
		questions: string[];
	}>;
	submitEvent: (action: string, data: Record<string, unknown>) => Promise<void>;
}

export const ParseAccountRecoverytoken = ({
	client,
	token: input,
}: {
	client: AccountRecoveryClient;
	token?: string;
	path?: string;
}): JSX.Element => {
	const [error, setError] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>(
		"There was an error verifying your invitation.",
	);

	useEffect(() => {
		const verify = async () => {
			try {
				// The input might have had dummy query parameters added by the user's email system. We strip them off here.
				const inputParts = input.split("&");
				const token = inputParts[0];
				const { success, questions } =
					await client.verifyAccountRecoveryToken(token);

				if (success) {
					client.token = token;
					client.userData = { securityQuestions: questions };
					route("/recover-account/security-questions");
				} else {
					setError(true);
					setErrorMessage("Unable to retrieve security questions");
					setTimeout(() => {
						route("/");
					}, 3000);
				}
			} catch (e) {
				try {
					await client.submitEvent("Parse account recovery token", {
						error: (e as Error).message,
					});
				} catch {
					// Swallow this error
				}
				setError(true);
				setErrorMessage(
					"There was an error retrieving your security questions. Please try again.",
				);
				setTimeout(() => {
					route("/");
				}, 3000);
			}
		};

		void verify();
	}, []);

	return (
		<Fragment>
			{!error && (
				<div id="loaderWrapper">
					<div className="loaderContainer">
						<div className="loader" />
					</div>
				</div>
			)}

			{error && (
				<Toast
					error={true}
					message={errorMessage}
					closeFunc={async () => Promise.resolve()}
				/>
			)}
		</Fragment>
	);
};
