import { QResponse, resultType } from "../data";

export interface FieldData {
	key: string;
	label: string;
	type:
		| "text"
		| "textarea"
		| "number"
		| "radio"
		| "multiselect"
		| "subradio"
		| "submultiselect"
		| "textWithOptions"
		| "radioOption"
		| "multiselectOption"
		| "textRadioOption"
		| "textMultiselectOption"
		| undefined;
	required?: boolean;
	name?: string;
	value?: string | string[];
	exclusiveKeys?: string[];
	options?: {
		value: string;
		label?: string;
		question?: FieldData;
		type?: "textRadioOption" | "textMultiselectOption";
		className?: string;
		exclusive?: boolean;
	}[];

	layout?: {
		insertBreak?: boolean;
		className?: string;
	};
}

export const updateText = (
	key: string,
	label: string,
	value: string,
	path: string[],
	results: Map<string, resultType>,
): Map<string, resultType> => {
	const newResults = resultsDeepCopy(results);

	let existing = newResults.get(path[0]);

	existing = getResultsNode(existing, path);

	(existing as QResponse).value = value;
	return newResults;
};

export const getRadioValue = (
	path: string[],
	results: Map<string, resultType>,
): string => {
	let current = results.get(path[0]);
	if (!current) {
		return "";
	}

	current = getResultsNode(current, path);

	return (current as QResponse)?.value as string;
};

export const getTextValue = (
	path: string[],
	results: Map<string, resultType>,
): string => {
	let current = results.get(path[0]);
	if (!current) {
		return "";
	}

	current = getResultsNode(current, path);
	if (typeof (current as QResponse).value !== "string") {
		return "";
	}

	return (current as QResponse).value as string;
};

export const getMultiselectValue = (
	path: string[],
	results: Map<string, resultType>,
): string[] => {
	const responses = getMultiselectResponse(path, results);

	return responses.map((resp) => resp.value as string);
};

export const getMultiselectResponse = (
	path: string[],
	results: Map<string, resultType>,
): QResponse[] => {
	const first = results.get(path[0]);
	if (!first) {
		return [];
	}

	const current: resultType = getResultsNode(first, path);

	if (Array.isArray(current)) {
		return current.map((resp) => resp);
	}

	const previous: resultType = getResultsNode(
		first,
		path.slice(0, path.length - 1),
	);
	if (Array.isArray(previous)) {
		return previous.map((resp) => resp);
	}

	if (Array.isArray(previous.optionValue)) {
		return previous.optionValue.map((resp) => resp);
	}

	return [];
};

export const resultContainsKey = (
	path: string[],
	key: string,
	results: Map<string, resultType>,
	useOptionValue: boolean = false,
): boolean => {
	let current = results.get(path[0]);
	if (path[0] === key && current) {
		return true;
	}
	let next = current;
	let i = 1;
	while (next) {
		const currentPathElement = path[i];
		if (Array.isArray(current)) {
			const filtered = current.filter((resp) => resp.key === key);
			if (filtered.length > 0) {
				return true;
			}

			const otherFiltered = current.filter(
				(resp) => resp.key === currentPathElement,
			);
			if (otherFiltered.length > 0) {
				next = current;
				current = otherFiltered[0];
			}
		} else if (current.key === key) {
			return true;
		} else if (typeof current.value === "string") {
			return false;
		} else {
			if (useOptionValue && current.optionValue) {
				current = current.optionValue;
			} else {
				current = current.value;
			}

			next = current;
		}
		i++;
		if (i >= path.length + 1) {
			return false;
		}
	}

	return false;
};

export const getResultsNode = (startNode: resultType, path: string[]) => {
	let current = startNode;

	for (const item of path) {
		if (Array.isArray(current)) {
			const filtered: QResponse[] = current.filter((resp) => resp.key === item);
			if (filtered.length === 0) {
				break;
			} else {
				current = filtered[0];
			}
		} else if (current?.value?.hasOwnProperty("key")) {
			const nextVal = current.value as QResponse;
			if (nextVal.key === item) {
				current = nextVal;
			}
		} else if (Array.isArray(current?.optionValue)) {
			const filtered: QResponse[] = current.optionValue.filter(
				(resp) => resp.key === item,
			);
			if (filtered.length === 0) {
				break;
			} else {
				current = filtered[0];
			}
		} else if (current?.optionValue?.hasOwnProperty("key")) {
			const nextVal = current.optionValue;
			if (nextVal.key === item) {
				current = nextVal;
			} else {
				break;
			}
		} else if (typeof current.value !== "string") {
			current = current.value;
		}
	}

	return current;
};

export const replaceArrayValue = (
	newResults: Map<string, resultType>,
	path: string[],
	newValue: QResponse[],
	useOptionValue: boolean,
) => {
	if (path.length === 1) {
		newResults.set(path[0], newValue);
		return;
	}

	const existing = newResults.get(path[0]);
	const current = getResultsNode(existing, path);

	if (current === existing) {
		newResults.set(path[0], newValue);
	} else if (Array.isArray(current)) {
		current.splice(0, current.length);
		current.push(...newValue);
	} else {
		if (useOptionValue) {
			current.optionValue = newValue;
		} else {
			current.value = newValue;
		}
	}
};

export const formContainsData = (results: Map<string, resultType>): boolean => {
	const values = results.values();
	for (const value of values) {
		if (valueContainsData(value)) {
			return true;
		}
	}
	return false;
};

const valueContainsData = (value: resultType): boolean => {
	if (Array.isArray(value)) {
		if (value.length === 0) {
			return false;
		}

		return value.some((val) => valueContainsData(val));
	}

	if (value.value) {
		if (typeof value.value === "string") {
			return !!value.value;
		}

		return valueContainsData(value.value);
	}

	if (typeof value.optionValue === "string") {
		return !!value.optionValue;
	}

	if (value.optionValue) {
		return valueContainsData(value.optionValue);
	}

	return false;
};

export const resultsDeepCopy = (
	results: Map<string, resultType>,
): Map<string, resultType> => {
	const copy = new Map<string, resultType>(results);
	results.forEach((value, key) => {
		copy.set(key, copyResultType(value));
	});

	return copy;
};

const copyResultType = (value: resultType): resultType => {
	if (Array.isArray(value)) {
		const copy: QResponse[] = [];
		value.forEach((val) => {
			copy.push(copyQResponse(val));
		});
		return copy;
	}

	return copyQResponse(value);
};

const copyQResponse = (response: QResponse): QResponse => {
	let value = response.value;
	if (typeof response.value !== "string") {
		value = copyResultType(response.value);
	}

	let optionValue = response.optionValue;
	if (response.optionValue) {
		optionValue = copyResultType(response.optionValue);
	}

	return {
		...response,
		value,
		optionValue,
	};
};
