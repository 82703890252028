/* eslint-disable react/react-in-jsx-scope */
import { Fragment, h } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import { LegalClient } from "../../../../client";
import { Toast } from "../../../../../../lib/components/toast";

import "./styles.scss";
import "../../../styles.scss";
import { LocConfirmed } from "../../components/loc-confirmed";
import { AuthClientContext } from "../../../../../../lib/init";
import { route } from "preact-router";

export const ConfirmLoc = ({
	client,
}: {
	client: LegalClient;
	path: string;
}): JSX.Element => {
	const [inErrorState, setInErrorState] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [newLocId, setNewLocId] = useState<string>("");
	const [newLocName, setNewLocName] = useState<string>("");
	const [newLocEmail, setNewLocEmail] = useState<string>("");
	const [confirmed, setConfirmed] = useState<boolean>(false);
	const authClient = useContext(AuthClientContext);

	useEffect(() => {
		if (!authClient.loggedIn) {
			route("/");
			return;
		}

		const validate = async () => {
			try {
				const { id, name, email } = await client.validateActivationToken(
					client.token,
				);
				setNewLocId(id);
				setNewLocName(name);
				setNewLocEmail(email);
			} catch (error) {
				setErrorMsg((error as Error).message);
				setInErrorState(true);
			}
		};
		void validate();
	}, []);

	const activateLoc = async () => {
		const loader = document.getElementById("loaderWrapper");
		loader.style.display = "flex";

		try {
			try {
				await client.login(client.username, password);
			} catch (error) {
				setErrorMsg((error as Error).message);
				setInErrorState(true);
				return;
			}

			try {
				await client.activateNewLoc(newLocId);
				setConfirmed(true);
			} catch (error) {
				setErrorMsg((error as Error).message);
				setInErrorState(true);
			}
		} finally {
			loader.style.display = "none";
		}
	};

	return (
		<Fragment>
			<main id="confirmLocPage">
				<section className="formWrapper">
					{!confirmed && (
						<Fragment>
							<div id="titleWrapper">
								<h1>Adding a Legal Options Counselor</h1>
							</div>
							<div id="textWrapper">
								<p>
									Callisto is requesting your confirmation for the below contact
									to be added as a Legal Options Counselor for Callisto Vault.
									If you did not expect this request, please reach out directly
									to Callisto. Otherwise, please confirm the new Legal Options
									Counselor by entering your password and clicking Confirm
									below.
								</p>

								<div className="newLocCard">
									<p>
										<b>New Legal Options Counselor&apos;s Name</b>
										<br />
										{newLocName}
									</p>

									<p>
										<b>Email</b>
										<br />
										{newLocEmail}
									</p>
								</div>
							</div>

							<input
								className="cred"
								id="password"
								name="password"
								type="password"
								placeholder="Password*"
								required={true}
								onChange={(evt) => {
									setInErrorState(false);
									setPassword(evt.currentTarget.value);
								}}
							/>

							<div id="next5">
								<button className="btn primary" onClick={activateLoc}>
									Confirm Legal Options Counselor
								</button>
							</div>
						</Fragment>
					)}

					{confirmed && <LocConfirmed name={newLocName} />}
				</section>
			</main>

			<div id="loaderWrapper">
				<div className="loaderContainer">
					<div className="loader" />
				</div>
			</div>

			{inErrorState && (
				<Toast
					error={true}
					message={errorMsg}
					closeFunc={async () => Promise.resolve()}
				/>
			)}
		</Fragment>
	);
};
