/* eslint-disable @typescript-eslint/member-ordering */
import fetch from "cross-fetch";

import * as t from "io-ts";
import * as internal from "./types";
import { Payload } from "../crypto";
import { autodecode, autoencode } from "../format";
import { iotsValidateThrow, tSodiumBytes } from "../../src/server/util/iots";
import { VaultClient } from "../../client/lib/clientcommon";

export * from "../format";

/**
 * RPCClient keeps in common all of the necessary pieces that an application
 * in our system will need to talk to its API in a secure way.
 */
export abstract class RPCClient extends VaultClient {
	// Credential Storage ========================================================
	// These properties are stored locally; they are useful for an initial
	// implementation and local testing but in browsers should be stored in a
	// more durable storage.
	private LOCAL_currentUsername: string;
	private LOCAL_currentUserID: string;
	private LOCAL_userPrivateKey: Uint8Array;
	private LOCAL_userPublicKey: Uint8Array;
	private LOCAL_serverPublicKey: Uint8Array;

	public get username(): string {
		return this.LOCAL_currentUsername;
	}
	public set username(value: string) {
		this.LOCAL_currentUsername = value;
	}

	public get userID(): string {
		return this.LOCAL_currentUserID;
	}
	public set userID(value: string) {
		this.LOCAL_currentUserID = value;
	}

	// Unprotected for testing
	get privateKey(): Uint8Array {
		return this.LOCAL_userPrivateKey;
	}
	set privateKey(value: Uint8Array) {
		this.LOCAL_userPrivateKey = value;
	}

	// Open for testing
	get publicKey(): Uint8Array {
		return this.LOCAL_userPublicKey;
	}
	set publicKey(value: Uint8Array) {
		this.LOCAL_userPublicKey = value;
	}

	protected get serverKey(): Uint8Array {
		return this.LOCAL_serverPublicKey;
	}

	protected set serverKey(value: Uint8Array) {
		this.LOCAL_serverPublicKey = value;
	}

	// Allows tests to run.
	public setKeys(
		publicKey: Uint8Array,
		privateKey: Uint8Array,
		serverKey: Uint8Array,
	): void {
		this.publicKey = publicKey;
		this.privateKey = privateKey;
		this.serverKey = serverKey;
	}

	// loggedIn is a signal that the client is properly signed in and should
	// be able to interact with secure parts of the system.
	public get loggedIn(): boolean {
		return (
			this.username !== undefined &&
			this.userID !== undefined &&
			this.privateKey !== undefined &&
			this.publicKey !== undefined &&
			this.serverKey !== undefined
		);
	}

	// logout destroys all the information and keys that a client is holding
	// for the user.
	public async logout(): Promise<void> {
		super.logout();

		this.username = undefined;
		this.userID = undefined;
		this.privateKey = undefined;
		this.publicKey = undefined;
		this.serverKey = undefined;
	}

	public serialize(): string {
		return JSON.stringify(
			autoencode({
				username: this.username,
				userID: this.userID,
				pk: this.publicKey,
				sk: this.privateKey,
				serverKey: this.serverKey,
			}),
		);
	}

	public deserialize(o: string): void {
		const s = autodecode(JSON.parse(o)) as {
			username: string;
			userID: string;
			pk: Uint8Array;
			sk: Uint8Array;
			serverKey: Uint8Array;
		};

		this.username = s.username;
		this.userID = s.userID;
		this.publicKey = s.pk;
		this.privateKey = s.sk;
		this.serverKey = s.serverKey;
	}

	// Request Internals =========================================================
	/**
	 * Log some user action to Beacon, our internal events collector.
	 */
	public async beacon(userData: Record<string, unknown>): Promise<void> {
		try {
			await fetch("/api/v0/beacon", {
				method: "POST",
				mode: "same-origin",
				cache: "no-cache",
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/json",
				},
				redirect: "follow",
				referrerPolicy: "no-referrer",
				body: JSON.stringify({
					...userData,
					// Always include the version indicator.
					version: 1,
				}),
			});
		} catch (reason) {
			console.log(reason);
			// Don't pass along errors here, these logs shouldn't break the operation
			// of other systems.
		}
	}

	/**
	 * call is a thin wrapper over rawRequest that just always does JSON
	 * decoding.
	 **/
	call<I extends t.Type<any, any, any>, O extends t.Type<any, any, any>>(
		decl: internal.RPCFunction<I, O>,
	): (args: t.TypeOf<I>) => Promise<t.TypeOf<O>> {
		return async (params: t.TypeOf<I>): Promise<t.TypeOf<O>> => {
			const {
				name: rpcName,
				i: input,
				o: output,
				XXX_THINK_CAREFULLY_no_token: skipToken,
			} = decl();
			const resp = await this.rawCall(rpcName, skipToken, input.encode(params));

			if (resp.status !== 200) {
				throw await resp.json();
			}

			return iotsValidateThrow(output, await resp.json()) as (
				args: t.TypeOf<I>,
			) => Promise<t.TypeOf<O>>;
		};
	}

	// encryptedRequest encrypts information between client/server in a way
	// that allows the client to prove their identity on each request.
	// protected encryptedCall<I, O>(decl: internal.RPCFunction<I, O>): (args: I) => Promise<O> {
	encryptedCall<
		I extends t.Type<any, any, any>,
		O extends t.Type<any, any, any>,
	>(
		decl: internal.RPCFunction<I, O>,
	): (args: t.TypeOf<I>) => Promise<t.TypeOf<O>> {
		return async (params: t.TypeOf<I> | void): Promise<t.TypeOf<O>> => {
			const {
				name: rpcName,
				i: input,
				o: output,
				XXX_THINK_CAREFULLY_no_token: skipToken,
			} = decl();
			// Payloads can't be undefined, so we create a blank object and send it
			// across the wire. This still helps us do verification of data on the
			// wire.
			const a = input.encode(params ?? {}) as Uint8Array;
			const encryptedBody = Payload.encrypt(a, this.serverKey, this.privateKey);

			const tInput = t.type({
				id: t.string,
				payload: tSodiumBytes,
			});
			const tOutput = t.type({
				payload: tSodiumBytes,
			});

			const resp = await this.rawCall(
				rpcName,
				skipToken,
				tInput.encode({
					payload: encryptedBody,
					id: this.userID,
				}),
			);

			if (resp.status === 200) {
				const { payload: body } = iotsValidateThrow(tOutput, await resp.json());

				const decrypted = Payload.decryptBasic(
					body,
					this.serverKey,
					this.privateKey,
				) as unknown;

				return iotsValidateThrow(output, decrypted) as (
					args: t.TypeOf<I>,
				) => Promise<t.TypeOf<O>>;
			}
			throw await resp.json();
		};
	}

	/**
	 * rawCall sets some common options for all requests that our
	 * application does.
	 *
	 * @param rpcName Name of function to call.
	 * @param skipToken Avoid fetching a token before making this call.
	 * @param data Any additional data to send in the request body. Will be JSON-encoded.
	 */
	protected async rawCall(
		rpcName: string,
		skipToken: unknown,
		data: any = {},
	): Promise<Response> {
		const headers: Record<string, string> = {
			"Content-Type": "application/json",
		};

		if (!skipToken) {
			const token = await this.getToken(rpcName);
			headers["X-Callisto-Token"] = token; // TODO: remove this
			headers["Callisto-Token"] = token;
		}

		const options: RequestInit = {
			body: JSON.stringify(data),
			method: "POST",
			headers,
			mode: "same-origin",
			cache: "no-cache",
			credentials: "same-origin",
			redirect: "follow",
			referrerPolicy: "no-referrer",
		};

		return fetch(`${this.baseURL}/api/v1/rpc/${rpcName}`, options);
	}

	/**
	 * rawRequest sets some common options for all requests that our
	 * application does.
	 *
	 * @param path API path to access.
	 * @param data Any additional data to send in the request body. Will be JSON-encoded.
	 * @param additionalHeaders Additional headers to include beyond JSON.
	 */
	protected async rawRequest(
		path: string,
		data: any = {},
		additionalHeaders?: Record<string, string>,
	): Promise<Response> {
		const options: RequestInit = {
			body: JSON.stringify(autoencode(data)),
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				...(additionalHeaders ?? {}),
			},
			mode: "same-origin",
			cache: "no-cache",
			credentials: "same-origin",
			redirect: "follow",
			referrerPolicy: "no-referrer",
		};

		return fetch(`${this.baseURL}${path}`, options);
	}
}
