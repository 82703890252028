import { ensureHttp } from "./common";

const genericURLs = [
	"messages",
	"hashtag",
	"events",
	"pages",
	"groups",
	"bookmarks",
	"lists",
	"developers",
	"topic",
	"help",
	"privacy",
	"campaign",
	"policies",
	"support",
	"settings",
	"games",
	"people",
	"photo",
];

export function normalizeFacebook(input: string): string[] {
	const inputUrl = ensureHttp(input);
	const u = new URL(inputUrl);
	if (u.host !== "facebook.com" && !u.host.endsWith(".facebook.com")) {
		throw new Error(`${input} is not a valid Facebook URL`);
	}

	// This is needed for cases like /people/Bobby/4/?show_switched_toast=0&show_invite_to_follow=0
	const path = u.pathname.endsWith("/")
		? u.pathname.substring(0, u.pathname.length - 1)
		: u.pathname;
	if (path === "") {
		throw new Error(`${input} is not a valid Facebook URL`);
	}

	const parts = path.split("/");
	switch (parts[1].toLowerCase()) {
		// old-style numeric profiles
		// e.g., https://www.facebook.com/profile.php?id=100010279981469
		case "profile.php":
			const id = u.searchParams.get("id");
			if (id === null) {
				throw new Error(`${input} is not a valid Facebook URL`);
			}

			return [id];
		case "people":
			const last = parts.length - 1;
			if (parts[last] === "people") {
				// This catches instances where no profile name is given, which would
				// just catch "people" as the name. Obviously that's not valid.
				throw new Error(`${input} is not a valid Facebook URL`);
			}
			return [parts[last]];
		case "share":
			// We need to skip to the next part since that will be the actual ID.
			return [parts[2].toLowerCase()];
		case "":
			throw new Error(`${input} is not a valid Facebook URL`);
	}

	if (u.pathname.endsWith(".php")) {
		throw new Error(`${input} is not a valid Facebook URL`);
	}

	if (genericURLs.includes(parts[1])) {
		throw new Error(`${input} is not a valid Facebook URL`);
	}

	return [parts[1].toLowerCase()];
}
