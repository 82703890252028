import sodium from "libsodium-wrappers-sumo";
import { entryData, shareData } from "../data";

import * as emailValidator from "email-validator";
import { isPossiblePhoneNumber } from "react-phone-number-input";

import { Secretbox } from "./secretbox";
import { OPRF } from "./oprf";
import bigInt from "big-integer";

import { normalizeFacebook } from "../../client/lib/matching/facebook";

const KDF_CONTEXT = "matching";
const KDF_SUBKEY_A = 1;
const KDF_SUBKEY_K = 2;

/**
 * This is an implementation of Callisto's matching algorithm.
 */
export module Matching {
	/**
	 * @returns a key suitable for encrypting entry contents.
	 */
	export const makeEntryKey = (): Uint8Array => Secretbox.keygen();

	/**
	 * The public component of this key should be saved with the entry in the
	 * database, and the private component should be stored in the survivor's
	 * encrypted data.
	 *
	 * @returns a key pair suitable for signing a request to edit/delete the entry.
	 */
	export const makeOwnershipKey = (): sodium.KeyPair =>
		sodium.crypto_sign_keypair();

	export const makeAlpha = (upid: string): OPRF.Alpha => {
		const upidPoint = OPRF.makePoint(
			sodium.crypto_generichash(
				sodium.crypto_core_ristretto255_HASHBYTES,
				upid,
			),
		);
		return OPRF.mask(upidPoint);
	};

	export const makePHat = (
		unmaskedA: Uint8Array,
		unmaskedB: Uint8Array,
	): Uint8Array => {
		const size = Math.min(unmaskedA.length, unmaskedB.length);
		const combined = new Uint8Array(size);
		for (let i = 0; i < size; i++) {
			// eslint-disable-next-line no-bitwise
			combined[i] = unmaskedA[i] ^ unmaskedB[i];
		}

		// Ensure that we can use this for key derivation.
		return sodium.crypto_generichash(sodium.crypto_kdf_KEYBYTES, combined);
	};

	export const makeShare = (
		entryKey: Uint8Array,
		pHat: Uint8Array,
		userMarker: Uint8Array,
		type: string,
	): shareData => {
		const a = sodium.crypto_kdf_derive_from_key(
			sodium.crypto_secretbox_KEYBYTES,
			KDF_SUBKEY_A,
			KDF_CONTEXT,
			pHat,
		);
		const k = sodium.crypto_kdf_derive_from_key(
			sodium.crypto_secretbox_KEYBYTES,
			KDF_SUBKEY_K,
			KDF_CONTEXT,
			pHat,
		);

		const key = Secretbox.encrypt(k, entryKey);
		const slope = bigInt(sodium.to_hex(a), 16);
		const userHash = bigInt(sodium.to_hex(userMarker), 16);
		const intercept = bigInt(sodium.to_hex(k), 16);
		const share = slope.times(userHash).add(intercept);

		return {
			dek: key,
			x: userHash,
			y: share,
			type,
		};
	};

	export const normalizeUPIDs = (
		perpIDs: entryData["perpIDs"],
	): { [type: string]: { failed: string[]; normalized: string[] } } => {
		const normalized: {
			[type: string]: { failed: string[]; normalized: string[] };
		} = {};

		for (const type in perpIDs) {
			if (perpIDs.hasOwnProperty(type)) {
				const fixedIDs: string[] = [];
				const failedIDs: string[] = [];
				const ids = perpIDs[type] as string[];

				for (const id of ids) {
					// Always skip over blank IDs that might have been inserted by
					// the UI accidentally.
					if (id === "") {
						continue;
					}

					try {
						const no = validators[type](id);
						for (const n of no) {
							fixedIDs.push(n);
						}
					} catch {
						failedIDs.push(id);
					}
				}

				normalized[type] = { failed: failedIDs, normalized: fixedIDs };
			}
		}

		return normalized;
	};
}

export const validators: { [type: string]: (str: string) => string[] } = {
	twitter: (input: string): string[] => {
		if (input.startsWith("@")) {
			input = input.substring(1);
		}

		return [input.toLowerCase()];
	},
	facebook: normalizeFacebook,
	linkedin: (input: string): string[] => {
		const inputUrl = ensureHttp(input);
		const u = new URL(inputUrl);

		if (!/linkedin\.com/i.test(u.host)) {
			throw new Error(`${input} is not a valid LinkedIn URL.`);
		}
		// Strip off the last / if there is one, since it messes with parsing.
		const matches = /\/in\/(.*)/.exec(
			u.pathname.endsWith("/")
				? u.pathname.substring(0, u.pathname.length - 1)
				: u.pathname,
		);
		if (!matches) {
			throw new Error(`${input} is not a valid LinkedIn URL.`);
		}

		// Isolate the (alphanumeric) identifier if there's other stuff in the URL for some reason
		const moreMatches = /^([-a-zA-Z0-9]+)[^-a-zA-Z0-9]?/.exec(matches[1]);
		if (!moreMatches) {
			throw new Error(`${input} is not a valid LinkedIn URL.`);
		}
		return [moreMatches[1].toLowerCase()];
	},
	orcid: (input: string): string[] => {
		const inputUrl = ensureHttp(input);
		const url = new URL(inputUrl);

		if (!/orcid\.org/i.test(url.host)) {
			throw new Error(`${input} is not a valid ORCID iD URL.`);
		}

		// Strip off the last / if there is one, since it messes with parsing.
		const path = url.pathname.endsWith("/")
			? url.pathname.substring(0, url.pathname.length - 1)
			: url.pathname;

		// Excepting the initial '/', the path should BE the ORCID iD
		const id = path.substring(1);
		if (!/([0-9]{4}-){3}[0-9]{3}[0-9xX]/i.test(id)) {
			throw new Error(`${input} is not a valid ORCID iD URL.`);
		}

		// A valid ORCID iD has an UPPER case X (if it has any non-digits at all) as the final character
		return [id.toUpperCase()];
	},
	googlescholar: (input: string): string[] => {
		let inputUrl = ensureHttp(input);
		// Strip off the last / if there is one, since it messes with parsing.
		inputUrl = inputUrl.endsWith("/")
			? inputUrl.substring(0, inputUrl.length - 1)
			: inputUrl;
		const url = new URL(inputUrl);

		if (
			!/scholar\.google\.com/i.test(url.host) ||
			url.pathname !== "/citations"
		) {
			throw new Error(`${input} is not a valid Google Scholar URL`);
		}

		const searchParams = url.searchParams;
		let id: string;
		for (const param of searchParams) {
			if (param[0] === "user") {
				if (id) {
					throw new Error(`${input} is not a valid Google Scholar URL`);
				}
				id = param[1];
			}
		}

		if (!id || id.length !== 12) {
			throw new Error(`${input} is not a valid Google Scholar URL`);
		}

		// For a Google Scholar ID, capitalization matters, so we do not convert to lowercase
		return [id];
	},
	instagram: (input: string): string[] => {
		if (input.startsWith("@")) {
			input = input.substring(1);
		}
		return [input.toLowerCase()];
	},
	snapchat: (input: string): string[] => {
		if (input.startsWith("@")) {
			input = input.substring(1);
		}
		return [input.toLowerCase()];
	},
	tiktok: (input: string): string[] => {
		if (input.startsWith("@")) {
			input = input.substring(1);
		}
		return [input.toLowerCase()];
	},
	discord: (input: string): string[] => {
		if (input.startsWith("@")) {
			input = input.substring(1);
		}
		return [input.toLowerCase()];
	},
	reddit: (input: string): string[] => {
		if (input.startsWith("u/")) {
			input = input.substring(2);
		}
		return [input.toLowerCase()];
	},
	twitch: (input: string): string[] => {
		if (input.startsWith("@")) {
			input = input.substring(1);
		}
		return [input.toLowerCase()];
	},
	youtube: (input: string): string[] => {
		if (input.startsWith("@")) {
			input = input.substring(1);
		}
		return [input.toLowerCase()];
	},
	whatsapp: (input: string): string[] => {
		if (input.startsWith("@")) {
			input = input.substring(1);
		}
		return [input.toLowerCase()];
	},
	email: (input: string): string[] => {
		if (!emailValidator.validate(input)) {
			throw new Error(`${input} is not a valid email.`);
		}

		return [input.toLowerCase()];
	},
	phone: (input: string): string[] => {
		if (!isPossiblePhoneNumber(input)) {
			throw new Error(`${input} is not a valid phone number.`);
		}

		return [input];
	},
};

const ensureHttp = (url: string): string =>
	url.startsWith("http") ? url : `https://${url}`;
