import * as t from "io-ts";
import {
	tCaseStatus,
	tSodiumBytes,
	tSodiumDate,
	tSurveyResponse,
} from "./util/iots";

export const legalRpcSpec = {
	RequestCaseReassignment: {
		input: t.type({
			entryId: t.string,
			survivorId: t.union([t.string, t.null]),
		}),
		output: t.unknown,
	},

	GetVersionedKeysForLoc: {
		input: t.unknown,
		output: t.type({
			keys: t.array(
				t.type({
					version: t.number,
					encryptedKey: tSodiumBytes,
					publicKey: tSodiumBytes,
				}),
			),
		}),
		XXX_THINK_CAREFULLY_no_token: true,
	},

	SaveVersionedKeysForNewLoc: {
		input: t.type({
			locId: t.string,
			keys: t.array(
				t.type({
					version: t.number,
					key: tSodiumBytes,
				}),
			),
		}),
		output: t.type({
			success: t.boolean,
		}),
	},

	IsLocStar: {
		input: t.unknown,
		output: t.boolean,
	},

	SaveVersionedSharedKeys: {
		input: t.type({
			version: t.number,
			sharedPublicKey: tSodiumBytes,
			encryptedSecretKeys: t.array(
				t.type({
					locId: t.string,
					encryptedKey: tSodiumBytes,
				}),
			),
		}),
		output: t.unknown,
	},

	SubmitCaseNotes: {
		input: t.type({
			caseId: t.string,
			keyVersion: t.number,
			encryptedNotes: tSodiumBytes,
		}),
		output: t.type({
			notesId: t.string,
		}),
	},

	UpdateCaseNotes: {
		input: t.type({
			notesId: t.string,
			keyVersion: t.number,
			encryptedNotes: tSodiumBytes,
		}),
		output: t.unknown,
	},

	SetCaseClosed: {
		input: t.type({
			entryId: t.string,
			status: tCaseStatus,
			statusChangeDate: tSodiumDate,
			surveyResponse: t.array(tSurveyResponse),
		}),
		output: t.type({
			success: t.boolean,
		}),
	},

	GetEntriesForShareRecalculation: {
		input: t.unknown,
		output: t.type({
			entries: t.record(t.string, tSodiumBytes),
		}),
		XXX_THINK_CAREFULLY_no_token: true,
	},

	GetEntryKeysAndUserMarkersByEntryIds: {
		input: t.type({
			entryIds: t.array(t.string),
		}),
		output: t.type({
			results: t.record(
				t.string,
				t.type({
					entryKey: tSodiumBytes,
					userMarker: tSodiumBytes,
				}),
			),
		}),
		XXX_THINK_CAREFULLY_no_token: true,
	},

	RewriteShares: {
		input: t.record(
			t.string,
			t.array(
				t.type({
					dek: tSodiumBytes,
					x: t.string,
					y: t.string,
					type: t.string,
				}),
			),
		),
		output: t.type({
			success: t.boolean,
		}),
	},

	IsAbleToRewriteShares: {
		input: t.unknown,
		output: t.boolean,
	},
};
