/* eslint-disable react/react-in-jsx-scope */
import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { LegalClient } from "../../../../client";
import {
	closeCaseSurveyQuestions,
	noContactSurveyQuestions,
} from "./closeCaseSurveyQuestions";

import "./styles.scss";
import "../../../styles.scss";
import { useCloseCase } from "../../components/hooks/useCloseCase";
import { resultType, SurveyResponse } from "../../../../../../lib/data";
import { CaseStatus } from "../../../../../../lib/components/case-status";
import { Toast } from "../../../../../../lib/components/toast";
import { FieldData } from "../../../../../../lib/questionnaire/formFunctions";

const { getElement } = require("./GetElement") as {
	getElement: (
		data: FieldData,
		path: string[],
		results: Map<string, resultType>,
		setResults: (newResults: Map<string, resultType>) => void,
		disabled?: boolean,
	) => JSX.Element;
};

export const CloseCaseSurvey = ({
	client,
}: {
	client: LegalClient;
	path?: string;
}): JSX.Element => {
	const { caseId, status, statusChangeDate } = useCloseCase();
	const [results, setResults] = useState<Map<string, resultType>>(null);
	const [questionData, setQuestionData] = useState<FieldData[]>([]);
	const [errorMsg, setErrorMsg] = useState<string>(null);
	const [errorState, setErrorState] = useState<boolean>(false);

	const initialState: Map<string, resultType> = new Map<string, resultType>();
	const contactMade = status && status.startsWith("CLOSED");

	useEffect(() => {
		if (contactMade) {
			setQuestionData(closeCaseSurveyQuestions);
		} else {
			setQuestionData(noContactSurveyQuestions);
		}
	}, []);

	useEffect(() => {
		for (const qData of questionData) {
			addToInitialState(qData);
		}

		setResults(initialState);
	}, [questionData]);

	const addToInitialState = (qData: FieldData) => {
		if (qData.type === "multiselect") {
			initialState.set(qData.key, {
				key: qData.key,
				type: "question",
				label: qData.label,
				value: [],
			});
		} else {
			initialState.set(qData.key, {
				key: qData.key,
				type: "question",
				label: qData.label,
				value: "",
			});
		}
	};

	const onSubmit = async () => {
		const loader = document.getElementById("loaderWrapper");
		loader.style.display = "flex";

		try {
			if (!caseId) {
				setErrorMsg(
					"No case selected. Please return to the dashboard and select a case to close.",
				);
				setErrorState(true);
				return;
			}

			try {
				const parsedResults: SurveyResponse[] = [];
				results.forEach((qResp) =>
					parsedResults.push(parseAnswer(qResp) as SurveyResponse),
				);
				await client.setCaseClosed(
					caseId,
					status,
					statusChangeDate,
					parsedResults,
				);
				history.back();
			} catch (error) {
				setErrorMsg((error as Error).message);
				setErrorState(true);
			}
		} finally {
			loader.style.display = "none";
		}
	};

	const parseAnswer = (
		qResponse: string | resultType,
	): string | SurveyResponse | SurveyResponse[] => {
		if (Array.isArray(qResponse)) {
			const responses: SurveyResponse[] = [];
			for (const resp of qResponse) {
				responses.push(parseAnswer(resp) as SurveyResponse);
			}
			return responses;
		}

		if (typeof qResponse === "string") {
			return qResponse;
		}

		if (qResponse.type === "question") {
			return {
				key: qResponse.key,
				question: qResponse.label,
				answer: parseAnswer(qResponse.value),
			};
		}

		if (typeof qResponse.value === "string") {
			if (qResponse.label === "Other:") {
				if (qResponse.optionValue) {
					return {
						key: qResponse.key,
						question: `Other: ${qResponse.value}`,
						answer: parseAnswer(qResponse.optionValue),
					};
				}
				return `Other: ${qResponse.value}`;
			}

			return qResponse.label;
		}

		return {
			key: qResponse.key,
			question: qResponse.label,
			answer: parseAnswer(qResponse.value),
		};
	};

	return (
		<div className="content">
			<h1>Close Case Survey</h1>
			<h2>{caseId ? caseId : "NO CASE SELECTED"}</h2>
			{status && <CaseStatus status={status} />}
			<form
				onSubmit={async (e) => {
					e.preventDefault();
					await onSubmit();
				}}
			>
				<ol>
					{questionData.map((field) => (
						<li key={field.key + "li"}>
							{getElement(field, [field.key], results, setResults)}
						</li>
					))}
				</ol>
				<div className="buttons">
					<button
						type="button"
						className="secondary btn"
						onClick={(e) => {
							e.preventDefault();
							history.back();
						}}
					>
						Cancel
					</button>
					<button type="submit" className="primary btn">
						Submit
					</button>
				</div>
			</form>

			<div id="loaderWrapper">
				<div className="loaderContainer">
					<div className="loader" />
				</div>
			</div>

			{errorState && (
				<Toast
					error={true}
					message={errorMsg}
					closeFunc={async () => {
						setErrorState(false);
						return Promise.resolve();
					}}
				/>
			)}
		</div>
	);
};
