import { useAction, useSelector } from "@preact-hooks/unistore";
import { useContext, useEffect, useState } from "preact/hooks";
import { CompareFunction, locEntry } from "../../../../lib/data";
import { AppState, useAuthState } from "../../../../lib/init/store";
import { LegalClientContext } from "./components/LegalClientContext";

type LocState = AppState & { cases?: locEntry[] };

export const useCases = (): {
	cases: locEntry[];
	refreshCases: () => void;
	casesLoaded: boolean;
	sort: (comparer: CompareFunction) => void;
} => {
	const { loggedIn } = useAuthState();
	const stateCases = useSelector((state: LocState) => state.cases ?? []);
	const client = useContext(LegalClientContext);
	const setCases = useAction((state: LocState, cases: LocState["cases"]) => ({
		cases,
	}));
	const [casesLoaded, setCasesLoaded] = useState<boolean>(false);

	const refreshCases = () => {
		if (loggedIn) {
			setCasesLoaded(false);
			void client
				.getAssignedEntries()
				.then((cases) => {
					setCases(cases);
				})
				.finally(() => setCasesLoaded(true));
		} else {
			setCases([]);
		}
	};

	const sort = (comparer: CompareFunction) => {
		stateCases.sort(comparer);
	};

	useEffect(refreshCases, [loggedIn]);

	return { cases: stateCases, refreshCases, casesLoaded, sort };
};

export const useContactInfo = (): {
	contact: {
		username: string;
		name: string;
		email: string;
		practice: string;
		state: string;
	};
	setContactInfo: (info: {
		name: string;
		email: string;
		practice: string;
		state: string;
	}) => Promise<void>;
} => {
	const defaultState = {
		name: "",
		email: "",
		practice: "",
		state: "",
	};
	const client = useContext(LegalClientContext);
	const { loggedIn } = useAuthState();
	const [profile, setProfile] = useState(defaultState);

	const loadProfile = () => {
		if (loggedIn) {
			void client.getContactInfo().then((result) => {
				setProfile(result);
			});
		} else {
			setProfile(defaultState);
		}
	};

	const setContactInfo = async (info: {
		name: string;
		email: string;
		practice: string;
		state: string;
	}) => {
		await client.updateContactInfo(
			info.name,
			info.email,
			info.practice,
			info.state,
		);
		// We didnt' throw, so we know it's good
		setProfile(info);
	};

	useEffect(loadProfile, [loggedIn]);

	return {
		contact: { ...profile, username: client.username },
		setContactInfo,
	};
};
