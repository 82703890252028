/* eslint-disable react/react-in-jsx-scope */
import { Fragment, h, JSX } from "preact";
import { route } from "preact-router";
import { useState } from "preact/hooks";

import { useAuthState } from "../../../init/store";
import { Loader } from "../../Loader";
import { PasswordInput } from "../../PasswordInput";
import { RecoverClient } from "./types";

import "./BackupCredentials.scss";

///
//  Step 2 - use the backup code to change the password
//
export const BackupCredentials = ({
	client,
	backupCode,
	questionsAnswered,
}: {
	client: RecoverClient;
	backupCode?: string;
	questionsAnswered?: boolean;
}): JSX.Element => {
	const { login } = useAuthState();
	const [password, setPassword] = useState("");
	const [confirm, setConfirm] = useState("");
	const [errorText, setError] = useState<JSX.Element | null>(null);
	const [showLoader, setShowLoader] = useState(false);

	const resetPassword = async () => {
		if (!(questionsAnswered || backupCode)) {
			route("/reset-password", true);
		}

		if (password === "") {
			setError(<Fragment>Please enter a password.</Fragment>);
			return;
		}
		if (password.length < 8) {
			setError(
				<Fragment>
					This password is too short, please use at least 8 characters.
				</Fragment>,
			);
			return;
		}
		if (confirm === "") {
			setError(<Fragment>Please confirm your password.</Fragment>);
			return;
		}
		if (password !== confirm) {
			setError(<Fragment>Passwords do not match.</Fragment>);
			return;
		}

		setShowLoader(true);

		try {
			try {
				await client.checkPasswordStrength(password);
			} catch (err) {
				setError(
					<p>
						The password you chose has been compromised on another website (
						<a
							href="https://haveibeenpwned.com/Passwords"
							target="_blank"
							rel="noreferrer"
						>
							click here for more details
						</a>
						). Please choose a new password.
					</p>,
				);
				return;
			}

			if (backupCode) {
				try {
					await client.burnBackupCode(backupCode, password);
				} catch (err) {
					setError(
						<Fragment>There was a problem resetting your password.</Fragment>,
					);
					return;
				}
			}

			try {
				login();
				await client.bootstrap();
				await client.submitEvent("Reset password", {
					path: questionsAnswered ? "account recovery" : "recovery code",
				});
				route("/dashboard");
			} catch (err) {
				setError(
					<Fragment>
						Your password was reset, but there was an error logging you in.
						Please try logging in again.
					</Fragment>,
				);
			}
		} finally {
			setShowLoader(false);
		}
	};

	return (
		<main id="backupNewPassword">
			<section className="formWrapper">
				<h1>Reset Password</h1>
				<br />
				<div id="credForm">
					<PasswordInput
						value={password}
						onChange={setPassword}
						placeholder="Create password 8+ characters"
					/>
					<PasswordInput
						value={confirm}
						onChange={setConfirm}
						placeholder="Confirm password"
					/>
					{errorText}
					<div id="next5" className="show">
						<button className="btn primary" onClick={resetPassword}>
							Reset Password
						</button>
					</div>
				</div>
				<Loader show={showLoader} />
			</section>
		</main>
	);
};
