/* eslint-disable react/react-in-jsx-scope */
import { h } from "preact";

interface SecurityQuestionTipsProps {
	baseFontSize?: string;
	titleFontSize?: string;
}

export const SecurityQuestionTips = ({
	baseFontSize = "13px",
	titleFontSize = "24px",
}: SecurityQuestionTipsProps): h.JSX.Element => (
	<div
		style={{
			textAlign: "left",
			border: "1px solid",
			borderColor: "#00375B",
			padding: "1em",
			fontSize: baseFontSize,
		}}
	>
		<h2 style={{ textAlign: "center" }}>
			Tips for Making Strong Answers to Security Questions
		</h2>
		<p style={{ fontSize: baseFontSize }}>
			The best secret question answers have four characteristics. They are:
		</p>
		<ol style={{ marginLeft: "2em" }}>
			<li>
				<b>Safe</b> (The answers cannot be easily researched or guessed.)
			</li>
			<li>
				<b>Stable</b> (The answer will not change over time.)
			</li>
			<li>
				<b>Memorable</b> (If you forget the answer, the question does not work
				as it should.)
			</li>
			<li>
				<b>Simple and specific</b> (Something complex or vague may be too
				difficult to remember.)
			</li>
		</ol>

		<p style={{ fontSize: baseFontSize }}>
			It is not always easy to meet all 4 characteristics, but at least keep the
			following in mind:
		</p>
		<ol style={{ marginLeft: "2em" }}>
			<li>
				Avoid picking answers that are well known to your friends, shared on
				social media, or common knowledge.
			</li>
			<li>
				Avoid picking answers that can be easily found using a search engine
				such as Google.
			</li>
			<li>Avoid picking answers that you may not be able to remember later.</li>
			<li>
				Avoid really short answers if possible. (A good minimum limit is 8
				characters.)
			</li>
		</ol>

		<p style={{ fontSize: baseFontSize }}>
			The very best answers have no obvious relationship to the question but
			that you will be able to remember. If you are not sure if you will
			remember your answers, use a password manager to store them.
		</p>

		<h3
			style={{
				textAlign: "center",
				fontWeight: "bold",
				fontSize: titleFontSize,
			}}
		>
			Answers to security questions are case sensitive!
		</h3>
	</div>
);
