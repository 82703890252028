/* eslint-disable react/react-in-jsx-scope */
import { Fragment, h } from "preact";
import { useState } from "preact/hooks";
import { Loader } from "./Loader";
import { PasswordInput } from "./PasswordInput";

interface Client {
	checkPasswordStrength(password: string): Promise<unknown>;
	updatePassword(oldPassword: string, newPassword: string): Promise<unknown>;
}

export const AccountPasswordInformation = ({
	client,
}: {
	client: Client;
}): JSX.Element => {
	const [loading, setLoading] = useState(false);
	const [changingPassword, setChangingPassword] = useState(false);
	const [errorString, setErrorString] = useState("");
	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const openChanging = () => {
		setChangingPassword(true);
		setNewPassword("");
		setConfirmPassword("");
		setErrorString("");
	};

	const savePassword = async () => {
		if (oldPassword === "") {
			setErrorString("Please enter current password.");
			return;
		}
		if (newPassword === "") {
			setErrorString("Please enter a password.");
			return;
		}
		if (newPassword.length < 8) {
			setErrorString(
				"This password is too short, please use at least 8 characters.",
			);
			return;
		}
		if (confirmPassword === "") {
			setErrorString("Please confirm your password.");
			return;
		}
		if (newPassword !== confirmPassword) {
			setErrorString("Passwords do not match.");
			return;
		}

		setLoading(true);

		try {
			try {
				await client.checkPasswordStrength(newPassword);
			} catch {
				setErrorString(
					`<p>The password you chose has been compromised on another website
          (<a href="https://haveibeenpwned.com/Passwords" target="_blank">click here for more details</a>).
          Please choose a new password.</p>`,
				);
				return;
			}

			try {
				await client.updatePassword(oldPassword, newPassword);
			} catch {
				setErrorString(
					"There was a problem updating your password. Please try again",
				);
			}
			setChangingPassword(false);
		} finally {
			setLoading(false);
		}
	};

	if (!changingPassword) {
		return (
			<Fragment>
				<label htmlFor="">
					<h2>Password</h2>
					<div id="passwordFiller">
						<img
							src={require("../assets/icons/lock.svg") as string}
							alt="lock"
						/>
						<input
							disabled
							type="password"
							name="password"
							value="password"
							style={{ color: "#d1e7e7", backgroundColor: "#f5f7f7" }}
						/>
					</div>
				</label>
				<div id="passwordButtons">
					<button
						type="button"
						className="secondaryButton"
						id="changePassword"
						onClick={openChanging}
					>
						Change Password
					</button>
				</div>
			</Fragment>
		);
	}

	return (
		<div id="newPasswordSection">
			<div id="newInputs">
				<label>
					<span>Current Password</span>
					<PasswordInput
						value={oldPassword}
						onChange={setOldPassword}
						placeholder="Enter current password"
					/>
				</label>
				<label>
					<span>New Password</span>
					<PasswordInput
						value={newPassword}
						onChange={setNewPassword}
						placeholder="Enter new password"
					/>
				</label>
				<label>
					<span>Re-enter New Password</span>
					<PasswordInput
						value={confirmPassword}
						onChange={setConfirmPassword}
						placeholder="Confirm password"
					/>
				</label>
				<p
					className="error"
					dangerouslySetInnerHTML={{ __html: errorString }}
				/>
			</div>
			<div id="passwordButtons">
				<button
					type="button"
					className="blankButton"
					onClick={() => setChangingPassword(false)}
				>
					Cancel
				</button>
				<button type="button" className="primaryButton" onClick={savePassword}>
					Save Changes
				</button>
			</div>
			<Loader show={loading} />
		</div>
	);
};
