// Inner data type definitions for encrypted data blobs.
import * as big from "big-integer";

export const MANY_ENTRIES = 10;

// Keypair that the user will use to encrypt anything intended for them.
export interface envelopeContents {
	pk: Uint8Array;
	sk: Uint8Array;
}

// DLOC-specific data (security questions, etc)
export interface dlocUserData {
	securityQuestions?: string[];
	securityAnswers?: string[];
	phoneNumber?: string;
	marker?: Uint8Array;
	recoveryOwnershipKey?: Uint8Array;
	signingKeys?: {
		publicKey: Uint8Array;
		privateKey: Uint8Array;
	};
}

export interface dlocUserDataConstructor {
	readonly prototype: dlocUserData;
	new (): dlocUserData;
}

// LOC-specific data (security questions, etc)
export interface locUserData {
	securityQuestions?: string[];
	securityAnswers?: string[];
	phoneNumber?: string;
	marker?: Uint8Array;
	recoveryOwnershipKey?: Uint8Array;
}

export interface locUserDataConstructor {
	readonly prototype: locUserData;
	new (): locUserData;
}

// Admin-specific data (security questions, etc)
export interface adminUserData {
	securityQuestions?: string[];
	securityAnswers?: string[];
	phoneNumber?: string;
	marker?: Uint8Array;
	recoveryOwnershipKey?: Uint8Array;
	signingKeys?: {
		publicKey: Uint8Array;
		privateKey: Uint8Array;
	};
}

export interface adminUserDataConstructor {
	readonly prototype: adminUserData;
	new (): adminUserData;
}

export interface userDataConstructor {
	readonly prototype: userData;
	new (): userData;
}

// Survivor-specific data (owned IDs, &c).
export interface userData {
	entries?: {
		[entryID: string]: { ownershipKey: Uint8Array; dek: Uint8Array };
	};
	draftEntries?: {
		[entryId: string]: { ownershipKey: Uint8Array; dek: Uint8Array };
	};
	records?: {
		[recordId: string]: { ownershipKey: Uint8Array; dek: Uint8Array };
	};
	incidentLogs?: {
		[incidentLogId: string]: { ownershipKey: Uint8Array; dek: Uint8Array };
	};
	consentIds?: string[];
	contactInfoKey?: Uint8Array;
	entryMarker?: Uint8Array;
	callistoContactEmail?: string;
	phoneNumber?: string;
	securityQuestions?: string[];
	securityAnswers?: string[];
	recoveryOwnershipKey?: Uint8Array;
}

export interface profileDataConstructor {
	readonly prototype: profileData;
	new (): profileData;
}

export interface profileData {
	email: string;
	phone: string;
}

// User contact information for LOCs.
export interface contactInfo {
	name?: string;
	phone?: string;
	email?: string;
	preferredLanguage?: string;
	accommodationsNeeded?: string;
}

// Encrypted share information for each LOC, holds the points
// used for interpolating the secret share equation.
export interface shareData {
	// Key used to encrypt the assignment data and data keys for entries.
	dek: Uint8Array;

	// X and Y coordinates of the graph used to interpolate the secret share
	// equation.
	x: big.BigInteger;
	y: big.BigInteger;

	// Perp ID type used to generate this share. Used for keeping statistics
	// on what data has been submitted, and to constrain matches to the same
	// Perp ID type.
	type: string;
}

// Actual entry information.
export interface entryData {
	id?: string;
	preferredLanguage?: string;
	accommodationsNeeded?: string;
	incidentState: string;
	userID: string;
	perpIDs: {
		twitter?: string[];
		facebook?: string[];
		linkedin?: string[];
		instagram?: string[];
		snapchat?: string[];
		tiktok?: string[];
		whatsapp?: string[];
		phone?: string[];
		email?: string[];
		discord?: string[];
		reddit?: string[];
		twitch?: string[];
		youtube?: string[];
		orcid?: string[];
		googlescholar?: string[];
	};
}

export interface userEntry {
	id: string;
	data: entryData;
	created: Date;
	edited: Date;
	status: string;
}

export interface recordData {
	year?: number;
	dayOfMonth?: number;
	month?: string;
	time?: string;
	season?: string;
	whenDetails?: string;
	place?: string;
	placeDetails?: string;
	anyoneSaw?: string;
	anyoneInteract?: string;
	witnessDetails?: string;
	toldAnyone?: string;
	peopleYouToldDetails?: string;
	offenderTold?: string;
	reasonsForLackOfConsent?: string[];
	whatHappened?: string;
	numberOfOffenders?: string;
	offenderName?: string;
	offenderDetails?: string;
	offenderWithAnyoneElse?: string;
	offenderCompanionDetails?: string;
	electronicEvidence?: string;
	physicalEvidence?: string;
}

export interface userRecord {
	id: string;
	data: recordData;
	created: Date;
	edited: Date;
}

export interface encryptedPerpID {
	type: string;
	index: string;
}

export interface identifiedEncryptedNotes {
	notesId: string;
	encrypted: Uint8Array;
}

export interface assignmentDataConstructor {
	readonly prototype: assignmentData;
	new (): assignmentData;
}

// Assignment data that the DLOC will view.
export interface assignmentData {
	preferredLanguage?: string;
	accommodationsNeeded?: string;
	incidentState: string;
	userID: string;
}

// Entry data that the DLOC will view.
export interface dlocEntry {
	id: string;
	created: Date;
	lastEdited: Date;
	matchFound: Date;
	userID: string;
	incidentState: string;
	preferredLanguage?: string;
	accommodationsNeeded?: string;
	userSignupCampus: string;
	userCampusAtCreation: string;
	status: CaseStatus | null;
	statusChanged: Date;
	statusHistory: { status: string; dateUpdated: Date }[];
	perpetratorId: string;
	assignedLOC: {
		id: string;
		name: string;
	};
	dateAssigned: Date;
	closeOutQuestionnaire: closeOutQuestionnaire;
	assignmentHistory: {
		locName: string;
		dateAssigned: Date;
		dateUnassigned?: Date;
	}[];
	notes: string;
	survivorHasManyEntries: boolean;
}

export enum CaseStatus {
	NO_CONTACT_ATTEMPTED = "NO_CONTACT_ATTEMPTED",
	CONTACT_ATTEMPTED = "CONTACT_ATTEMPTED",
	IN_CONTACT = "IN_CONTACT",
	NEEDS_REASSIGNMENT = "NEEDS_REASSIGNMENT",
	CASE_CLOSED_NO_RESPONSE = "CASE_CLOSED_NO_RESPONSE",
	SHARE_REPAIRED = "SHARE_REPAIRED",
	CLOSED_COORDINATING_ACTION = "CLOSED_COORDINATING_ACTION",
	CLOSED_NOT_COORDINATING = "CLOSED_NOT_COORDINATING",
	NEW_CASE = "NEW_CASE",
	CLOSED_DO_NOT_CONTACT = "CLOSED_DO_NOT_CONTACT",
	FALSE_MATCH = "FALSE_MATCH",
	DELETED_ENTRY = "DELETED_ENTRY",
	REOPENED_CASE = "REOPENED_CASE",
	DELETED_FALSE_MATCH = "DELETED_FALSE_MATCH",
}

export interface locEntry {
	id: string;
	created: Date;
	lastEdited: Date;
	assigned: Date;
	matchFound: Date;
	entry: entryData;
	userSignupCampus: string;
	userCampusAtTimeOfEntryCreation: string;
	userContactInfo: contactInfo;
	status: CaseStatus;
	statusChanged: Date;
	perpID: string;
	numMatches: number;
	closeCaseSurveyCompleted: boolean;
	survivorHasManyEntries: boolean;
}

export interface legalNotesConstructor {
	readonly prototyp: legalNotes;
	new (): legalNotes;
}

export interface legalNotes {
	id?: string;
	createdDate: Date;
	modifiedDate: Date;
	notes: string;
}

export interface closeOutQuestionnaireConstructor {
	readonly prototype: closeOutQuestionnaire;
	new (): closeOutQuestionnaire;
}

export interface closeOutQuestionnaire {
	timesSpoken: string;
	comms: {
		phone: boolean;
		email: boolean;
		sms: boolean;
		video: boolean;
	};
	intake: boolean;
	extantAttorney: boolean;
	caseMemo: boolean;
	feedback: boolean;
	decision: boolean;
}

export interface versionedKeyBoxConstructor {
	readonly prototype: versionedKeyBox;
	new (): versionedKeyBox;
}

export interface versionedKeyBox {
	key: Uint8Array;
	version: number;
}

export interface encryptedBoxConstructor {
	readonly prototype: encryptedBox;
	new (): encryptedBox;
}

export interface encryptedBox {
	keyVersion: number;
	encrypted: Uint8Array;
}

export interface loc {
	id: string;
	name: string;
	email: string;
	practice: string;
	state: string;
	dateAdded: any;
	dateDeactivated?: Date;
}

export interface admin {
	id: string;
	name: string;
	email: string;
	isRegistered: boolean;
	isActivated: boolean;
	isActive: boolean;
	dateDeactivated?: Date;
}

export interface dloc {
	id: string;
	name: string;
	email: string;
	isRegistered: boolean;
	isActivated: boolean;
	isActive: boolean;
	dateDeactivated?: Date;
}

export const closeOutQuestions = {
	timesSpoken: "How many times did you speak with the client?",
	comms: "How did you communicate with the client?",
	intake: "Did you complete an intake?",
	extantAttorney: "Did the client already have an attorney?",
	caseMemo: "Did you send the client a case closing memo?",
	feedback: "Did you send the client the anonymous feedback survey?",
	decision: "The survivor reached a decision about what they want to do next.",
} as const;

export type CompareFunction = (
	a: dlocEntry & locEntry,
	b: dlocEntry & locEntry,
) => number;

export type caseType = "open" | "closed" | "deleted" | "false" | "all";

export interface LegalFilters {
	caseTypes: caseType;
	custom: {
		statuses: string;
		survivors: string;
		matchDates: string;
		perpetrators: string;
		incidentStates: string;
		campuses: string;
		assignedDates: string;
	};
	statuses: string[];
	survivors: string[];
	matchDates: string[];
	perpetrators: string[];
	incidentStates: string[];
	campuses: string[];
	assignedDates: string[];
}

export interface DlocFilters {
	caseTypes: caseType;
	custom: {
		ids: string;
		statuses: string;
		matchDates: string;
		survivors: string;
		perpetrators: string;
		incidentStates: string;
		campuses: string;
		assignedLocs: string;
		assignedDates: string;
	};
	ids: string[];
	statuses: string[];
	matchDates: string[];
	survivors: string[];
	perpetrators: string[];
	incidentStates: string[];
	campuses: string[];
	assignedLocs: string[];
	assignedDates: string[];
}

export interface SurveyResponseConstructor {
	readonly prototype: SurveyResponse;
	new (): SurveyResponse;
}

export interface SurveyResponse {
	key: string;
	question: string;
	answer: string | SurveyResponse | SurveyResponse[];
}

export interface QResponse {
	key: string;
	value: string | resultType;
	type: "question" | "option";
	optionValue?: resultType; // Used for text fields with options
	label: string;
}

export type resultType = QResponse | QResponse[];

export interface logData {
	responses: Map<string, resultType>;
	updated: Date;
}

export type incidentLogData = Record<string, logData>;

export interface userIncidentLog {
	id: string;
	data: incidentLogData;
	created: Date;
	updated: Date;
}

export const formatStatus = (input: string) => {
	const arr = input.split("_");
	for (let i = 0; i < arr.length; i++) {
		arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
	}
	return arr.join(" ");
};

export const formatDate = (date: Date) => {
	if (!(date instanceof Date)) {
		return "";
	}
	const year = date.getFullYear();
	const month = date.getMonth() + 1;
	const day = date.getDate();

	const updatedMonth = fillOutDateNum(month);
	const updatedDay = fillOutDateNum(day);

	// \u2011 is a non breaking '-'
	return `${year}\u2011${updatedMonth}\u2011${updatedDay}`;
};

export const fillOutDateNum = (date: number) => {
	let goal = date.toString();
	if (date >= 0 && date < 10) {
		goal = "0" + goal;
	}
	return goal;
};

interface NonSurvivorAccountRecoveryClient {
	userData: dlocUserData | locUserData | adminUserData;
}

export const accountRecoveryIsSetUp = (
	client: NonSurvivorAccountRecoveryClient,
) => {
	const userData = client.userData;
	return (
		userData.phoneNumber &&
		userData.marker &&
		userData.securityAnswers &&
		userData.securityQuestions &&
		userData.recoveryOwnershipKey
	);
};
