/* eslint-disable react/react-in-jsx-scope */
import { h } from "preact";
import { Router } from "preact-router";
import { StoreProvider } from "@preact-hooks/unistore";

import { AuthClientContext, renderShell } from "../../../../lib/init";
import { Navbar } from "../../../../lib/components/legal-nav";
import { LoginPage } from "../../../../lib/components/login";
import { EnterBackupCodesPage } from "../../../../lib/components/backup-codes/recover";
import { LegalClient } from "../../client";
import { LegalClientContext } from "./components/LegalClientContext";
import { store } from "../../../../lib/init/store";

import { DashboardPage } from "./pages/dashboard";
import { ViewCasePage } from "./pages/view-case";
import { AccountSettingsPage } from "./pages/account-settings";
import { WithIdleTimer } from "../../../../lib/components/WithIdleTimer";
import { AuthRouter } from "../../../../lib/components/AuthRouter";
import { CreateCredentials } from "./pages/create-credentials";
import { CreateAccountToken } from "./components/sign-up-token";
import { ConfirmLoc } from "./pages/confirm-loc";
import { CloseCaseSurvey } from "./pages/close-case-survey";
import { useEffect, useState } from "preact/hooks";
import { RecoverySetup } from "../../../../lib/components/recovery-setup";
import { ParseAccountRecoverytoken } from "../../../../lib/components/ParseAccountRecoveryToken";
import { AnswerSecurityQuestions } from "../../../../lib/components/security-questions/AnswerSecurityQuestions";

const client = new LegalClient();

const Main = () => {
	const [envVars, setEnvVars] = useState<Record<string, string> | null>(null);

	useEffect(() => {
		const getEnvVars = async () => {
			setEnvVars(await client.getEnvironmentVariables());
		};

		if (!envVars) {
			void getEnvVars();
		}
	}, []);

	return (
		<AuthClientContext.Provider value={client}>
			<LegalClientContext.Provider value={client}>
				<StoreProvider value={store}>
					<WithIdleTimer>
						<Router>
							<LoginPage
								path="/"
								client={client}
								target="/dashboard"
								portalName="LOC Portal"
							/>
							<CreateCredentials path="/create-credentials" client={client} />
							<CreateAccountToken path="/create-account/token/:token" />
							<LoginPage
								path="/activate-loc/token/:token"
								client={client}
								target="confirm-loc/"
								portalName="LOC Portal"
							/>
							<ConfirmLoc path="/confirm-loc" client={client} />
							<LoginPage
								path="/url/:link*"
								client={client}
								target="/dashboard"
								portalName="LOC Portal"
							/>
							<EnterBackupCodesPage path="/reset-password" client={client} />
							<ParseAccountRecoverytoken
								client={client}
								path={"/recover-account/token/:token"}
							/>
							<AnswerSecurityQuestions
								client={client}
								type="loc"
								path="/recover-account/security-questions"
							/>
							<AuthRouter path="/:rest+">
								<ViewCasePage path="/case/:id" />
								<CloseCaseSurvey client={client} path="/close-case" />
								<DashboardPage path="/dashboard" />
								<AccountSettingsPage path="/account" />
								<RecoverySetup client={client} path="/setup-recovery/" />
							</AuthRouter>
						</Router>
						<Navbar
							navItems={[
								{
									title: "Resources",
									url: "https://drive.google.com/drive/folders/1n3pFDyNlfSC-gKFPQswDGMRezdEiFT4I?usp=sharing",
									newTab: true,
								},
							]}
						/>
					</WithIdleTimer>
				</StoreProvider>
			</LegalClientContext.Provider>
		</AuthClientContext.Provider>
	);
};

void renderShell(<Main />, client);
