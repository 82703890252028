/* eslint-disable react/react-in-jsx-scope */
import { h } from "preact";

interface Props {
	submitText: string;
	cancelText: string;
	returnText?: string;
	headerText: string;
	onReturn?: () => void;
	onSubmit(): void;
	onCancel(): void;
}

export const SubmitCancelReturn = ({
	submitText,
	cancelText,
	returnText,
	headerText,
	onReturn,
	onSubmit,
	onCancel,
}: Props): JSX.Element => (
	<div id="submitCancelReturn">
		<h2>{headerText}</h2>
		<div>
			<button className="secondaryButton" onClick={onCancel}>
				{cancelText}
			</button>
			{returnText && onReturn && (
				<button className="primaryButton" onClick={onReturn}>
					{returnText}
				</button>
			)}
			<button className="primaryButton" onClick={onSubmit}>
				{submitText}
			</button>
		</div>
	</div>
);
