/* eslint-disable react/react-in-jsx-scope */
import { Fragment, h } from "preact";
import "./styles.scss";
import { AuthClientContext } from "../../../../../../lib/init";
import { useAuthState } from "../../../../../../lib/init/store";
import { useContext } from "preact/hooks";
import { route } from "preact-router";

export const LocConfirmed = ({ name }: { name: string }): JSX.Element => {
	const authClient = useContext(AuthClientContext);
	const { logout } = useAuthState();

	const handleLogout = async () => {
		await authClient.logout();

		logout();

		route("/");
	};

	return (
		<Fragment>
			<main id="locConfirmedPage">
				<div id="titleWrapper">
					<h1>The Legal Options Counselor has been added</h1>
				</div>
				<div id="textWrapper">
					<p>
						Thank you for confirming {name} for us. If you have any questions or
						concerns. please contact{" "}
						<a href="mailto:support@projectcallisto.org">
							support@projectcallisto.org
						</a>
					</p>
				</div>

				<div id="next5">
					<button className="btn primary" onClick={handleLogout}>
						Log out
					</button>
				</div>

				<p className="helperText">
					<a href="/dashboard">Go to dashboard</a>
				</p>
			</main>
		</Fragment>
	);
};
