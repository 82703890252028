/* eslint-disable react/react-in-jsx-scope */
import { Fragment, h } from "preact";
import { DlocFilters, LegalFilters } from "../../data";
import "./styles.scss";

interface FilterProps {
	filter: string;
	label: string;
	filters: LegalFilters | DlocFilters;
	onChange: (filter: string, value: string) => void;
	values: {
		value: string;
		displayValue?: string;
	}[];
}

export const StringFilter = ({
	filter,
	label,
	filters,
	onChange,
	values,
}: FilterProps): JSX.Element => (
	<Fragment>
		<div className="stringFilter">
			<label htmlFor={filter}>{label}: </label>
			<select
				id={filter}
				value={filters[filter] as string}
				onChange={(e) => {
					onChange(filter, e.currentTarget.value);
				}}
			>
				{values.map((val) => (
					<option value={val.value} key={val.value}>
						{val.displayValue ?? val.value}
					</option>
				))}
			</select>
		</div>
	</Fragment>
);
